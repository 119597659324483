// 3rd Party
import _ from 'lodash';
import Cookies from 'js-cookie';

// Other
import request, { GET, POST, PUT, upload } from '../utils/request';
import { dataUrlToBlob } from '../utils/files';

const getPatientEndpoint = patientId =>
  `${CONFIG.API_URL}/patient/${patientId}`;

export const apiGetEmailVerificationCode = patientId =>
  request(POST, `${getPatientEndpoint(patientId)}/emailVerificationRequest`);

export const apiGetPhoneVerificationCode = (patientId, verificationType) =>
  request(POST, `${getPatientEndpoint(patientId)}/phoneVerificationRequest`, {
    verificationType,
  });

export const apiGetPhoneVerificationSmsStatus = patientId =>
  request(GET, `${getPatientEndpoint(patientId)}/phoneVerificationSmsStatus`);

export const apiGetUser = () =>
  request(GET, `${CONFIG.API_URL}/user`, {
    params: {
      deep: true,
    },
  });

export const apiLoginUser = (email, password) =>
  request(POST, `${CONFIG.API_URL}/login`, {
    email,
    password,
  });

export const apiLoginUserWithToken = (email, token) =>
  request(POST, `${CONFIG.API_URL}/loginToken`, {
    email,
    token,
  });

export const apiLogoutUser = () => request(POST, `${CONFIG.API_URL}/logout`);

export const apiSignupUserAsPatient = userData =>
  request(POST, `${CONFIG.API_URL}/signup`, {
    signupType: 'patient',
    email: userData.email,
    password: userData.password,
    firstName: userData.firstName,
    lastName: userData.lastName,
    mobilePhone: userData.mobilePhone,
    gender: userData.gender,
    dateOfBirth: userData.dateOfBirth,
    settings: userData.settings,
    isAffiliate: userData.isAffiliate,
    patientSignupSource: userData.patientSignupSource,
  });

export const apiUpdatePatient = (
  patientId,
  patientDetails,
  autoVerify = false,
) =>
  request(
    PUT,
    `${getPatientEndpoint(patientId)}?autoVerify=${autoVerify}`,
    patientDetails,
  );

export const apiValidatePromoCode = code =>
  request(GET, `${CONFIG.API_URL}/OfferType/validatePromoCode/${code}/`);

export const apiVerifyEmail = (patientId, code) =>
  request(POST, `${getPatientEndpoint(patientId)}/emailVerification`, {
    code,
  });

export const apiVerifyPhone = (patientId, code) =>
  request(POST, `${getPatientEndpoint(patientId)}/phoneVerification`, {
    token: code,
  });

export const apiRequestAppointment = (
  providerId,
  patient,
  requestedSlot,
  offer,
  requestData,
  offeredAppointmentTypeId,
  insuranceData,
  funnelConfiguration,
  promotion,
  anyProviderSelected,
) => {
  const utmSource = Cookies.get('oc.forwardQueryString.utm_source');
  const gclid = Cookies.get('oc.forwardQueryString.gclid');

  return request(
    POST,
    `${CONFIG.API_URL}/patient/appointment/request/${providerId}`,
    {
      insuranceData,
      insuranceFeedbackId: insuranceData.insuranceFeedbackId || null,
      offeredAppointmentType: offeredAppointmentTypeId,
      offerType: _.get(offer, 'active') ? offer.id : undefined,
      patient: {
        dateOfBirth: patient.dateOfBirth || null,
        email: patient.email,
        firstName: patient.firstName,
        gender: patient.gender || null,
        lastName: patient.lastName,
        mobilePhone: patient.mobilePhone,
        settings: patient.settings || {},
      },
      requestBlocks: null,
      requestData,
      reservedAppointmentSlot:
        // The API expects manually recurring IB slots to have type 'Opencare Automatic' and NOT have an id, so we clear it here
        // The front-end expects an id for each slot so for these specific slots we generate a fake id in the API
        requestedSlot && requestedSlot.type !== 'Opencare Automatic'
          ? requestedSlot.id
          : null,
      reservedSlotDetails: {
        date: requestedSlot ? requestedSlot.date : null,
        provider: requestedSlot
          ? requestedSlot.provider.id || requestedSlot.provider
          : null,
        time: requestedSlot ? requestedSlot.time : null,
        type: _.get(requestedSlot, 'type', 'Opencare'),
        metadata: requestedSlot ? requestedSlot.metadata : null,
      },
      funnelConfiguration,
      anyProviderSelected,
      gclid: utmSource === 'adwords' && gclid ? gclid : undefined,
    },
  );
};

export const apiUploadAppointmentInsuranceCardPhotos = (
  appointmentId,
  cardFrontDataUrl,
  cardBackDataUrl,
  // eslint-disable-next-line consistent-return
) => {
  let cardFrontFile;
  let cardBackFile;

  if (cardFrontDataUrl) {
    const cardFrontBlob = dataUrlToBlob(cardFrontDataUrl);
    cardFrontFile = new File([cardFrontBlob], 'insurance-card-front.jpeg', {
      type: cardFrontBlob.type || 'image/jpeg',
    });
  }
  if (cardBackDataUrl) {
    const cardBackBlob = dataUrlToBlob(cardBackDataUrl);
    cardBackFile = new File([cardBackBlob], 'insurance-card-back.jpeg', {
      type: cardBackBlob.type || 'image/jpeg',
    });
  }

  if (cardFrontFile || cardBackFile) {
    const formData = new FormData();
    if (cardFrontFile) formData.append('photo-front', cardFrontFile);
    if (cardBackFile) formData.append('photo-back', cardBackFile);

    return upload(
      POST,
      `${CONFIG.API_URL}/patient/appointment/${appointmentId}/insurancePhotos`,
      formData,
    );
  }
};
