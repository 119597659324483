import Logger from 'utils/Logger';

export const extractErrorMessage = error => {
  const apiMessage = error?.response?.data?.errors?.[0];

  // Failsafe this method just in case no message is found
  return apiMessage || error?.message || error;
};

export const extractErrorDetails = error => {
  const apiMessage = error?.response?.data?.message;

  if (apiMessage) {
    return {
      error_details: apiMessage,
    };
  }

  return null;
};

/**
 * Send an error to Rollbar.
 * DO NOT SEND INFORMATION THAT COULD POTENTIALLY BE USED TO IDENTIFY A PATIENT.
 */
export const sendToLogger = (error, methodName, errorDetails) => {
  const errorMessage = extractErrorMessage(error);
  const errorToLog = error;
  errorToLog.method_that_threw_error = methodName;
  errorToLog.error_details = errorDetails;

  Logger.error(`API: ${errorMessage}`, errorToLog);
};
