import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';
import { apiGetOffer } from 'endpoints/match-endpoints';
import { call, put } from 'redux-saga/effects';
import { SagaHandler } from '../SagaHandler';

class _RequestOffer extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/REQUEST_OFFER',
      stateKey: 'requestOffer',
      legacyOptions: {
        valueStateKey: 'offer',
      },
      dependencies: ['REQUEST_REFFERAL_PROMOTION'],
    });
  }

  *saga({ payload }) {
    const offerCode = payload;
    const errorHandler = this.error;
    const loadingHandler = this.loading;
    const valueHandler = this.value;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));
      const offer = yield call(apiGetOffer, offerCode);
      yield put(valueHandler.update(offer));

      const requestReferralPromotion = this._dependencies
        .REQUEST_REFFERAL_PROMOTION;
      yield put(requestReferralPromotion.value.update(null));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      sendToLogger(error, 'requestOffer');
      yield put(errorHandler.update(errorMessage));
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const RequestOffer = new _RequestOffer();
