import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import loDebounce from 'lodash/debounce';
import Analytics from 'analytics-opencare';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectDataPatient } from 'connect/selectors';
import { UpdatePatientSilently } from 'connect/saga/handlers/update-patient-silently/update-patient-silently';

const analytics = new Analytics();

const RouteListener = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const patient = useSelector(selectDataPatient);
  const patientRef = useRef(patient);

  // Update the ref whenever patient changes to prevent stale closures
  useEffect(() => {
    patientRef.current = patient;
  }, [patient]);

  const tryUpdatePatient = loDebounce(
    patientId => {
      dispatch(
        UpdatePatientSilently.createAction({
          patientId,
          lastSeenAt: moment.utc().toISOString(),
        }),
      );
    },
    // 5 seconds debounce (do not update more than once every 5 seconds).
    5000,
    {
      // Fire on the leading edge only
      leading: true,
      trailing: false,
    },
  );

  useEffect(() => {
    analytics.trackDefaultPageView();

    const unlisten = history.listen(() => {
      analytics.trackDefaultPageView();

      // Watch for router changes and update patient last seen at time
      if (patientRef?.current?.id) {
        tryUpdatePatient(patientRef.current.id);
      }
    });

    return () => unlisten();
  }, [history]);

  return props.children;
};

export default RouteListener;
