// 3rd Party
import { filter, get, includes } from 'lodash';
import { createSelector } from 'reselect';

// Other
import {
  getClinicMapMarker,
  getResultsMapMarkers,
} from 'pages/common/helpers/google-maps-helpers';
import { COUNTRY } from '../common/constants';
import { NUM_MATCH_RESULTS_TO_DISPLAY } from './constants';

// Internal
const selectRouter = state => state.router;

const selectDataResults = state => get(state.data, 'results');

// Exports
export const selectDataExcludeProviderId = state =>
  get(state.data, 'excludeProviderId');

export const makeSelectDataResults = createSelector(
  selectDataResults,
  selectDataExcludeProviderId,
  (results, excludeProviderId) =>
    filter(results, r => !includes(r.providerIds, excludeProviderId)).slice(
      0,
      NUM_MATCH_RESULTS_TO_DISPLAY,
    ),
);

export const selectDataAddressPostalCode = state =>
  get(state.data, 'match.location.address.postal_code');

export const selectDataAddressState = state =>
  get(state.data, 'match.location.address.administrative_area_level_1');

export const selectDataAddressStateShort = state =>
  get(state.data, 'match.location.address.administrative_area_level_1_short');

export const selectDataCheckoutFormData = state =>
  get(state.data, 'checkoutFormData');

export const selectDataClinic = state => get(state.data, 'clinic');

export const selectDataClinicId = state => get(state.data, 'clinic.id');

export const selectDataClinicCountry = state =>
  get(state.data, 'clinic.address.country');

export const selectDataClinicState = state =>
  get(state.data, 'clinic.address.administrative_area_level_1');

export const selectDataClinicStateShort = state =>
  get(state.data, 'clinic.address.administrative_area_level_1_short');

export const selectDataClinicLocality = state =>
  get(state.data, 'clinic.address.locality');

export const selectDataLatitude = state =>
  get(state.data, 'match.location.latitude');

export const selectDataLongitude = state =>
  get(state.data, 'match.location.longitude');

export const selectDataCountry = state =>
  get(state.data, 'match.location.address.country');

export const selectDataEmailVerifiedAt = state =>
  get(state.data, 'patient.emailVerifiedAt');

export const selectDataHasInsurance = state =>
  get(state.data, 'match.other.hasInsurance');

export const selectDataInsurance = state =>
  get(state.data, 'match.other.insurance', {});

export const selectDataLocality = state =>
  get(state.data, 'match.location.address.locality');

export const selectDataLoadingEmailVerification = state =>
  get(state.data, 'loadingEmailVerification');

export const selectDataLoadingPhoneVerification = state =>
  get(state.data, 'loadingPhoneVerification');
export const selectDataLoadingPhoneVerificationRequest = state =>
  get(state.data, 'loadingPhoneVerificationRequest');

export const selectDataCreatingPatient = state =>
  get(state.data, 'creatingPatient');

export const selectDataLoadingUser = state => get(state.data, 'loadingUser');

export const selectDataLoadingLogin = state => get(state.data, 'loadingLogin');

export const selectDataLoadingMatch = state => get(state.data, 'loadingMatch');

export const selectDataMatch = state => get(state.data, 'match');

export const selectDataMatchId = state => get(state.data, 'match.id');

export const selectDataMatchConfiguration = state =>
  get(state.data, 'match.matchConfiguration');

export const selectDataMatchInsuranceId = state =>
  get(state.data, 'match.other.insurance.planId');

export const selectDataMatchToken = state => get(state.data, 'matchToken');

export const selectDataPatient = state => get(state.data, 'patient');

export const selectDataPhoneVerifiedAt = state =>
  get(state.data, 'patient.phoneVerifiedAt');

export const selectDataPhoneVerificationSmsStatus = state =>
  get(state.data, 'phoneVerificationSmsStatus');

export const selectDataProfileLink = state => get(state.data, 'profileLink');

export const selectDataReason = state => get(state.data, 'match.other.reason');

export const selectDataRequestedAppointment = state =>
  get(state.data, 'requestedAppointment');

export const selectDataAppointment = state => get(state.data, 'appointment');

export const selectDataSelectedResultIndex = state =>
  get(state.data, 'selectedResultIndex');

export const selectDataUser = state => get(state.data, 'user');

export const selectDataPartner = state => get(state.data, 'user.partner');

export const selectDataLoginFormError = state =>
  get(state.data, 'loginFormError');

export const selectValidatePromoCodeError = state =>
  get(state.data, 'validatePromoCodeError');

export const selectDataPhoneVerificationSmsStatusError = state =>
  get(state.data, 'phoneVerificationSmsStatusError');

export const selectCJTags = state => state.data.cjTags;

export const selectDataLoadingReferralPromotion = state =>
  get(state.data, 'loadingPromotion');

export const selectDataLoadingEligiblePartners = state =>
  get(state.data, 'loadingEligiblePartners', false);

export const selectDataLoadingPhoneVerificationSmsStatus = state =>
  get(state.data, 'loadingPhoneVerificationSmsStatus');
export const selectDataEligiblePartners = state =>
  get(state.data, 'eligiblePartners');

export const selectBookingWidgetSlot = state =>
  get(state.data, 'bookingWidgetSlot');

export const selectDataInsuranceList = state =>
  get(state.data, 'insuranceList');

export const selectDataInsuranceListRegion = state =>
  get(state.data, 'insuranceListRegion');

export const selectDataInsuranceListForCanada = state =>
  get(state.data, 'insuranceListForCanada');

export const selectPypestreamReady = state =>
  get(state.data, 'pypestreamReady');

export const selectEmailMatchClinicResult = state =>
  state.data?.emailMatchClinicResult;

export const makeSelectClinicMarker = createSelector(
  selectDataClinic,
  getClinicMapMarker,
);

export const makeSelectDetailedInsuranceMode = createSelector(
  selectDataHasInsurance,
  selectDataCountry,
  selectDataClinic,
  (hasInsurance, country, clinic) =>
    hasInsurance === 'Yes' &&
    country === COUNTRY.unitedStates &&
    get(clinic, 'address.country') === COUNTRY.unitedStates,
);

export const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState.location,
  );

export const makeSelectResultsMapMarkers = createSelector(
  makeSelectDataResults,
  getResultsMapMarkers,
);

export const makeSelectCheckoutData = createSelector(
  selectDataMatch,
  selectDataUser,
  selectDataPatient,
  selectDataRequestedAppointment,
  selectDataCheckoutFormData,
  makeSelectDetailedInsuranceMode,
  selectDataClinic,
  (
    match,
    user,
    patient,
    requestedAppointment,
    formData,
    detailedInsuranceMode,
    clinic,
  ) => ({
    match,
    user,
    patient,
    requestedAppointment,
    formData,
    detailedInsuranceMode,
    clinic,
  }),
);
