import request, { GET } from 'utils/request';
import { SagaHandler } from '../SagaHandler';
import { call, put } from 'redux-saga/effects';
import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';
import { getIdentity } from './utils';

const apiGetAnonymousSessionData = () => {
  const identity = getIdentity();
  const url = `${CONFIG.API_URL}/anonymous-session/${identity || ''}`;
  return request(GET, url);
};

class _GetAnonymousSessionData extends SagaHandler {
  constructor() {
    super({
      actionName: 'api/GET_ANONYMOUS_SESSION_DATA',
      stateKey: 'anonymousSessionData',
    });
  }

  *saga() {
    const errorHandler = this.error;
    const loadingHandler = this.loading;
    const valueHandler = this.value;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));
      const newData = yield call(apiGetAnonymousSessionData);
      yield put(valueHandler.update(newData));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);

      // Ignore errors if the item is not found
      const ignoreError =
        error?.response?.status === 404 &&
        errorMessage?.includes('Could not find item for identity');
      if (ignoreError) {
        return;
      }

      sendToLogger(error, 'GetAnonymousSessionData');
      yield put(errorHandler.update(errorMessage));
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const GetAnonymousSessionData = new _GetAnonymousSessionData();
