import {
  updateCheckoutErrorStatus,
  updateExistingUserEmail,
  updateLoadingUser,
  updateMatch,
  updatePatientDetails,
  updateUserAndPatient,
} from 'connect/actions';
import {
  CHECKOUT_ERROR_TYPES,
  getUserSignupErrorType,
} from 'connect/constants';
import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';
import { selectDataMatch, selectDataMatchToken } from 'connect/selectors';
import {
  apiGetUser,
  apiSignupUserAsPatient,
} from 'endpoints/patient-endpoints';
import { UpsertIterableUser } from '../upsert-iterable-user/upsert-iterable-user';
import { call, put, select } from 'redux-saga/effects';
import { SagaHandler } from '../SagaHandler';

class _Signup extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/SIGNUP_USER',
      stateKey: 'signup',
    });
  }

  createAction(userData, match, matchToken) {
    return {
      type: this._actionName,
      userData,
      match,
      matchToken,
    };
  }

  *saga({ userData, match, matchToken }) {
    const errorHandler = this.error;

    const currentMatch = match || (yield select(selectDataMatch));
    const currentMatchToken =
      matchToken || (yield select(selectDataMatchToken));
    yield put(updateLoadingUser(true));
    yield put(errorHandler.update(null));

    const patientData = {};
    if (currentMatch) {
      patientData.metadata = userData?.metadata || {};
      patientData.metadata.wizardData = { ...currentMatch.other } || {};
      patientData.metadata.wizardData.location = currentMatch.location;
    }

    let user;
    try {
      user = yield call(apiSignupUserAsPatient, userData);
      user = yield call(apiGetUser);
      yield put(updateUserAndPatient(user));

      // Add ownership of match to authenticated user
      if (currentMatch?.id && currentMatchToken) {
        yield call(updateMatch, {
          matchId: currentMatch.id,
          currentMatchToken,
        });
      }

      // If anything in patientData, make additional API call
      if (
        patientData.metadata &&
        Object.keys(patientData.metadata).length > 0
      ) {
        yield put(updatePatientDetails(patientData));
      }
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      const errorType = getUserSignupErrorType(errorMessage);
      yield put(errorHandler.update(errorType));

      // If signing user up failed and they have a match, it happened in the checkout flow
      // Otherwise, it happened in the funnel, and we don't want to affect this
      if (currentMatch) {
        if (errorType) {
          yield put(updateCheckoutErrorStatus(errorType));

          if (errorType === CHECKOUT_ERROR_TYPES.EMAIL_ALREADY_REGISTERED) {
            yield put(updateExistingUserEmail());
          }
        } else {
          yield put(updateCheckoutErrorStatus(null));
        }
      }

      sendToLogger(error, 'SignupUser');
    } finally {
      yield put(updateLoadingUser(false));

      // Separate try/catch block for Iterable user creation, after everything else is done, as this should never interfere with user signup in any way
      try {
        if (user?.email) {
          const signedUpInCheckout = Boolean(currentMatch);
          yield call(UpsertIterableUser.saga, {
            payload: {
              dataFields: { signedUpInCheckout },
            },
          });
        }
      } catch (error) {
        sendToLogger(error, 'upsertIterableUser');
      }
    }
  }
}

export const Signup = new _Signup();
