import request, { PUT } from 'utils/request';
import { SagaHandler } from '../SagaHandler';
import { call, put } from 'redux-saga/effects';
import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';
import { GetAnonymousSessionData } from './get-anonymous-session-data';
import { getIdentity } from './utils';

const apiUpdateAnonymousSessionData = data => {
  const identity = getIdentity();
  const url = `${CONFIG.API_URL}/anonymous-session/${identity || ''}`;
  return request(PUT, url, data);
};

class _UpdateAnonymousSessionData extends SagaHandler {
  constructor() {
    super({
      actionName: 'api/UPDATE_ANONYMOUS_SESSION_DATA',
      stateKey: 'updateAnonymousSessionDatas',
    });
  }

  *saga({ payload: data }) {
    const errorHandler = this.error;
    const loadingHandler = this.loading;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));
      yield call(apiUpdateAnonymousSessionData, data);
      yield put(GetAnonymousSessionData.value.update(data));
    } catch (error) {
      sendToLogger(error, 'UpdateAnonymousSessionData');

      const errorMessage = extractErrorMessage(error);
      yield put(errorHandler.update(errorMessage));
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const UpdateAnonymousSessionData = new _UpdateAnonymousSessionData();
