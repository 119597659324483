export const replaceEnd = (history, location, end) => {
  const routes = location ? location.pathname.split('/') : null;
  if (routes && routes.length === 0) return;
  const url = routes ? `${routes.slice(0, -1).join('/')}/${end}` : `/${end}`;
  history.replace(url);
};

export const pushEnd = (history, location, end) => {
  const routes = location ? location.pathname.split('/') : null;
  if (routes && routes.length === 0) return;
  const url = routes ? `${routes.slice(0, -1).join('/')}/${end}` : `/${end}`;
  history.push(url);
};

export const getParam = (search, ...params) => {
  const urlParams = {};

  if (search) {
    const searchString = search.substring(1);

    if (searchString) {
      const splitParams = searchString.split('&');

      for (let i = 0; i < splitParams.length; i += 1) {
        const pair = splitParams[i].split('=');
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1]);

        if (urlParams[key] === undefined) {
          // 1st occurance
          urlParams[key] = value;
        } else if (typeof urlParams[key] === 'string') {
          // 2nd occurance
          const arr = [urlParams[key], value];
          urlParams[key] = arr;
        } else {
          // 3rd+ occurance
          urlParams[key].push(value);
        }
      }
    }
  }

  const res = params.map(param => urlParams[param]);

  return res;
};

export const checkPath = (actualPathname, requestedPathname) => {
  const pattern = new RegExp(`^/${requestedPathname}/?$`);

  return pattern.test(actualPathname);
};

export const getMarketingParamsToQuery = () => {
  const { search } = window.location;
  const marketingParameters = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'dm_promo_code',
  ];

  return marketingParameters
    .map(param => `${param}=${getParam(search, param)}`)
    .join('&');
};
