import {
  CLEAR_CLINIC,
  CREATE_MATCH_AND_RESULTS,
  CREATE_PATIENT,
  GET_ELIGIBLE_PARTNERS,
  LOGIN_USER,
  LOGIN_USER_WITH_TOKEN,
  LOGOUT_USER,
  POST_FEEDBACK,
  POST_PARTNER_LEAD,
  RECREATE_MATCH_AND_RESULTS,
  REQUEST_APPOINTMENT,
  REQUEST_CLINIC,
  REQUEST_CLINIC_PERFORMANCE_BADGES,
  REQUEST_EMAIL_VERIFICATION,
  REQUEST_FEATURES_FOR_SPECIALTY,
  REQUEST_MATCH_AND_RESULTS,
  REQUEST_PHONE_VERIFICATION,
  REQUEST_PHONE_VERIFICATION_SMS_STATUS,
  REQUEST_SERVICES_FOR_SPECIALTY,
  REQUEST_USER_AND_PATIENT,
  RESET_CHECKOUT_FORM_DATA,
  RESET_MATCHES,
  RESET_MATCH_INSURANCE,
  SAVE_MATCHES,
  UPDATE_APPOINTMENT_REQUESTED,
  UPDATE_BOOKING_WIDGET_SLOT,
  UPDATE_CHECKOUT_ERROR_STATUS,
  UPDATE_CHECKOUT_FORM_DATA,
  UPDATE_CJ_TAGS,
  UPDATE_CLINIC,
  UPDATE_CLINIC_PERFORMANCE_BADGES,
  UPDATE_CREATING_PATIENT,
  UPDATE_DIRECT_TO_PROFILE_LINK,
  UPDATE_DM_PROPS,
  UPDATE_ELIGIBLE_PARTNERS,
  UPDATE_EXCLUDE_PROVIDER_ID,
  UPDATE_EXISTING_USER_EMAIL,
  UPDATE_FEATURES_FOR_SPECIALTY,
  UPDATE_INSURANCE_PROVIDER_AND_PLAN,
  UPDATE_LOADING_ELIGIBLE_PARTNERS,
  UPDATE_LOADING_EMAIL_VERIFICATION,
  UPDATE_LOADING_MATCH,
  UPDATE_LOADING_PHONE_VERIFICATION,
  UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS,
  UPDATE_LOADING_USER,
  UPDATE_LOGIN_ERROR,
  UPDATE_LOADING_LOGIN,
  UPDATE_MATCH,
  UPDATE_MATCH_AND_RESULTS,
  UPDATE_ONGOING_CARE_NO_MATCH,
  UPDATE_REASON_NO_MATCH,
  UPDATE_MEMBER_ID,
  UPDATE_PATIENT,
  UPDATE_PATIENT_DETAILS,
  UPDATE_PHONE_VERIFICATION_SMS_STATUS,
  UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR,
  UPDATE_SELECTED_APPOINTMENT,
  UPDATE_SELECTED_RESULT,
  UPDATE_SERVICES_FOR_SPECIALTY,
  UPDATE_SPECIFIC_AVAILABILITIES,
  UPDATE_USER_AND_PATIENT,
  UPDATE_VALIDATE_PROMO_CODE_ERROR,
  VALIDATE_PROMO_CODE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
  UPDATE_INSURANCE_LIST,
  GET_INSURANCE_LIST,
  UPDATE_INSURANCE_LIST_REGION,
  GET_INSURANCE_LIST_FOR_CANADA,
  UPDATE_INSURANCE_LIST_FOR_CANADA,
  UPDATE_PYPESTREAM_READY,
  EMAIL_MATCH_CLINIC,
  EMAIL_MATCH_CLINIC_RESULT,
} from './constants';

export const clearClinic = clinicId => ({
  type: CLEAR_CLINIC,
  clinicId,
});

export const createPatient = (partnerId, patientData) => ({
  type: CREATE_PATIENT,
  partnerId,
  patientData,
});

export const loginUser = (email, password) => ({
  type: LOGIN_USER,
  email,
  password,
});

export const loginUserWithToken = (authParam, deep) => ({
  type: LOGIN_USER_WITH_TOKEN,
  authParam,
  deep,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const requestAppointment = (
  providerId,
  requestedSlot,
  requestData,
  offeredAppointmentTypeId,
  insuranceData,
  funnelConfiguration,
  anyProviderSelected,
) => ({
  type: REQUEST_APPOINTMENT,
  providerId,
  requestedSlot,
  requestData,
  offeredAppointmentTypeId,
  insuranceData,
  funnelConfiguration,
  anyProviderSelected,
});

export const requestClinic = clinicIds => ({
  type: REQUEST_CLINIC,
  clinicIds,
});

export const requestClinicPerformanceBadges = clinicId => ({
  type: REQUEST_CLINIC_PERFORMANCE_BADGES,
  clinicId,
});

export const createMatchAndResults = (
  match,
  funnelConfiguration,
  matchResultsPageConfiguration,
) => ({
  type: CREATE_MATCH_AND_RESULTS,
  match,
  funnelConfiguration,
  matchResultsPageConfiguration,
});

export const recreateMatchAndResults = (
  matchId,
  matchToken,
  authParam,
  createNewMatch,
) => ({
  type: RECREATE_MATCH_AND_RESULTS,
  matchId,
  matchToken,
  authParam,
  createNewMatch,
});

export const requestMatchAndResults = (
  matchId,
  matchToken,
  userId,
  offerCode,
) => ({
  type: REQUEST_MATCH_AND_RESULTS,
  matchId,
  matchToken,
  userId,
  offerCode,
});

export const requestEmailVerification = () => ({
  type: REQUEST_EMAIL_VERIFICATION,
});

export const requestFeaturesForSpecialty = specialtyId => ({
  type: REQUEST_FEATURES_FOR_SPECIALTY,
  specialtyId,
});

export const requestPhoneVerification = verificationType => ({
  type: REQUEST_PHONE_VERIFICATION,
  verificationType,
});

export const requestPhoneVerificationSmsStatus = () => ({
  type: REQUEST_PHONE_VERIFICATION_SMS_STATUS,
});

export const requestServicesForSpecialty = specialtyId => ({
  type: REQUEST_SERVICES_FOR_SPECIALTY,
  specialtyId,
});

export const requestUserAndPatient = match => ({
  type: REQUEST_USER_AND_PATIENT,
  match,
});

export const resetCheckoutFormData = () => ({
  type: RESET_CHECKOUT_FORM_DATA,
});

export const resetMatchInsurance = () => ({
  type: RESET_MATCH_INSURANCE,
});

export const resetMatches = () => ({
  type: RESET_MATCHES,
});

export const saveMatches = email => ({
  type: SAVE_MATCHES,
  email,
});

export const updateAppointmentRequested = appointment => ({
  type: UPDATE_APPOINTMENT_REQUESTED,
  appointment,
});

export const updateCheckoutFormData = formData => ({
  type: UPDATE_CHECKOUT_FORM_DATA,
  formData,
});

export const updateClinic = payload => ({
  type: UPDATE_CLINIC,
  payload,
});

export const updateClinicPerformanceBadges = payload => ({
  type: UPDATE_CLINIC_PERFORMANCE_BADGES,
  payload,
});

export const updateDirectToProfileLink = directToProfile => ({
  type: UPDATE_DIRECT_TO_PROFILE_LINK,
  directToProfile,
});

export const updateDMProps = (promoCode, referralCode, referralPromo) => ({
  type: UPDATE_DM_PROPS,
  promoCode,
  referralCode,
  referralPromo,
});

export const updateBookingWidgetSlot = (providerId, date, time) => ({
  type: UPDATE_BOOKING_WIDGET_SLOT,
  providerId,
  date,
  time,
});

export const updateExcludeProviderId = providerId => ({
  type: UPDATE_EXCLUDE_PROVIDER_ID,
  providerId,
});

export const updateExistingUserEmail = () => ({
  type: UPDATE_EXISTING_USER_EMAIL,
});

export const updateInsuranceProviderAndPlan = newInsurance => ({
  type: UPDATE_INSURANCE_PROVIDER_AND_PLAN,
  newInsurance,
});

export const updateCreatingPatient = creatingPatient => ({
  type: UPDATE_CREATING_PATIENT,
  creatingPatient,
});

export const updateLoadingEmailVerification = loading => ({
  type: UPDATE_LOADING_EMAIL_VERIFICATION,
  loading,
});

export const updateLoadingPhoneVerification = loading => ({
  type: UPDATE_LOADING_PHONE_VERIFICATION,
  loading,
});

export const updateLoadingPhoneVerificationRequest = loading => ({
  type: UPDATE_LOADING_PHONE_VERIFICATION,
  loading,
});

export const updateLoadingUser = loadingUser => ({
  type: UPDATE_LOADING_USER,
  loadingUser,
});

export const updateLoadingLogin = loadingLogin => ({
  type: UPDATE_LOADING_LOGIN,
  loadingLogin,
});

export const updateLoadingMatch = loadingMatch => ({
  type: UPDATE_LOADING_MATCH,
  loadingMatch,
});

export const updateMatch = (matchId, matchToken, experimentData) => ({
  type: UPDATE_MATCH,
  matchId,
  matchToken,
  experimentData,
});

export const updateMatchAndResults = (matchAndResults, matchToken) => ({
  type: UPDATE_MATCH_AND_RESULTS,
  matchAndResults,
  matchToken,
});

// For use in the checkout flow when no match ID exists
// We need to store the reason here so it is sent when appt is created
export const updateOngoingCareNoMatch = ongoingCareNoMatch => ({
  type: UPDATE_ONGOING_CARE_NO_MATCH,
  ongoingCareNoMatch,
});

// For use in the checkout flow when no match ID exists
// We need to store the reason here so it is sent when appt is created
export const updateReasonNoMatch = reasonNoMatch => ({
  type: UPDATE_REASON_NO_MATCH,
  reasonNoMatch,
});

export const updateMemberId = memberId => ({
  type: UPDATE_MEMBER_ID,
  memberId,
});

export const updatePatient = (patient, patientDetails) => ({
  type: UPDATE_PATIENT,
  patient,
  patientDetails,
});

export const updatePatientDetails = (patientDetails, autoVerify) => ({
  type: UPDATE_PATIENT_DETAILS,
  patientDetails,
  autoVerify,
});

export const updatePhoneVerificationSmsStatus = phoneVerificationSmsStatus => ({
  type: UPDATE_PHONE_VERIFICATION_SMS_STATUS,
  phoneVerificationSmsStatus,
});

export const updateSelectedAppointment = (
  providerId,
  slotId,
  provider,
  anyProviderSelected,
) => ({
  type: UPDATE_SELECTED_APPOINTMENT,
  providerId,
  slotId,
  provider,
  anyProviderSelected,
});

export const updateSelectedResult = selectedResultIndex => ({
  type: UPDATE_SELECTED_RESULT,
  selectedResultIndex,
});

export const updateServicesForSpecialty = (services, specialtyId) => ({
  type: UPDATE_SERVICES_FOR_SPECIALTY,
  services,
  specialtyId,
});

export const updateFeaturesForSpecialty = (features, specialtyId) => ({
  type: UPDATE_FEATURES_FOR_SPECIALTY,
  features,
  specialtyId,
});

export const updateUserAndPatient = user => ({
  type: UPDATE_USER_AND_PATIENT,
  user,
});

export const updateValidatePromoCodeError = error => ({
  type: UPDATE_VALIDATE_PROMO_CODE_ERROR,
  error,
});

export const validatePromoCode = code => ({
  type: VALIDATE_PROMO_CODE,
  code,
});

export const verifyEmail = code => ({
  type: VERIFY_EMAIL,
  code,
});

export const verifyPhone = code => ({
  type: VERIFY_PHONE,
  code,
});

export const updateSpecificAvailabilities = specificAvailabilities => ({
  type: UPDATE_SPECIFIC_AVAILABILITIES,
  specificAvailabilities,
});

export const updateCheckoutErrorStatus = status => ({
  type: UPDATE_CHECKOUT_ERROR_STATUS,
  status,
});

export const updateLoginError = error => ({
  type: UPDATE_LOGIN_ERROR,
  error,
});

export const updateRequestPhoneVerificationSmsStatusError = error => ({
  type: UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR,
  error,
});

export const updateCJTags = cjTags => ({
  type: UPDATE_CJ_TAGS,
  cjTags,
});

export const postFeedback = (source, data) => ({
  type: POST_FEEDBACK,
  source,
  data,
});

export const updateLoadingPhoneVerificationSmsStatus = loading => ({
  type: UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS,
  loading,
});

export const requestEligiblePartners = location => ({
  type: GET_ELIGIBLE_PARTNERS,
  location,
});

export const updateLoadingEligiblePartners = loadingEligiblePartners => ({
  type: UPDATE_LOADING_ELIGIBLE_PARTNERS,
  loadingEligiblePartners,
});

export const updateEligiblePartners = eligiblePartners => ({
  type: UPDATE_ELIGIBLE_PARTNERS,
  eligiblePartners,
});

export const sendPartnerLead = (
  partnerId,
  patientInformation,
  location,
  leadInformation,
) => ({
  type: POST_PARTNER_LEAD,
  partnerId,
  patientInformation,
  location,
  leadInformation,
});

export const getInsuranceList = region => ({
  type: GET_INSURANCE_LIST,
  region,
});

export const updateInsuranceList = insuranceList => ({
  type: UPDATE_INSURANCE_LIST,
  insuranceList,
});

export const updateInsuranceListRegion = region => ({
  type: UPDATE_INSURANCE_LIST_REGION,
  region,
});

export const getInsuranceListForCanada = () => ({
  type: GET_INSURANCE_LIST_FOR_CANADA,
});

export const updateInsuranceListForCanada = insuranceListForCanada => ({
  type: UPDATE_INSURANCE_LIST_FOR_CANADA,
  insuranceListForCanada,
});

export const updatePypestreamReady = pypestreamReady => ({
  type: UPDATE_PYPESTREAM_READY,
  pypestreamReady,
});

export const emailMatchClinic = (matchId, clinicId, source) => ({
  type: EMAIL_MATCH_CLINIC,
  matchId,
  clinicId,
  source,
});

export const emailMatchClinicResult = result => ({
  type: EMAIL_MATCH_CLINIC_RESULT,
  result,
});
