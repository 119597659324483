/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import { PersistGate } from 'redux-persist/integration/react';
import Analytics from 'analytics-opencare';

// Import root app
import App from 'containers/App';

// Import antd styles so we can override them with our own in app.css
import 'opencare-crown';

// Base styles
import './app.css';

// Import Providers
import LanguageProvider from 'containers/LanguageProvider';
import WindowDimensionsProvider from 'providers/WindowDimensionsProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./assets/favicon.png';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';
import CONFIG from '../app.config';

// Import i18n messages
import { translationMessages } from './i18n';

/* eslint-disable import/order */
import Logger from './utils/Logger';
import { StorageSetup } from './utils/storage';
/* eslint-enable import/order */

/* eslint-disable no-new */
Logger.configure();
/* eslint-enable no-new */

// Setup localforage
StorageSetup();

// Create redux store with history
const initialState = {};
// The persistor is a separate package that allows the redux store to maintain it's state on refresh of the page by saving data in local storage. We're currently using this to stop the redux store from being wiped on refresh of the page as we won't have the matchToken or matchId in the url params anymore
const { store, persistor } = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('app');

// Initialize Opencare Analytics library
const analytics = new Analytics();
analytics.initialize({
  appId: `search-v2:${CONFIG.ENV}`,
  environment: CONFIG.ENV,
  ocApiUrl: CONFIG.API_URL,
});

// Component
const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider messages={messages}>
          <ConnectedRouter history={history}>
            <WindowDimensionsProvider>
              <App />
            </WindowDimensionsProvider>
          </ConnectedRouter>
        </LanguageProvider>
      </PersistGate>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
