// 3rd Party
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

// Other
import {
  apiGetClinicPerformanceBadges,
  apiGetClinics,
  apiGetFeaturesForSpecialty,
  apiGetServicesForSpecialty,
} from 'endpoints/clinic-endpoints';
import {
  apiCreateMatch,
  apiEmailMatchClinic,
  apiGetDirectMatch,
  apiGetMatch,
  apiGetOffer,
  apiPostFeedback,
  apiSendMatchEmail,
  apiUpdateMatch,
} from 'endpoints/match-endpoints';
import {
  apiCreateMatchForPartner,
  apiCreatePatientForPartner,
  apiGetLeadPartners,
  apiRequestAppointmentForPartner,
  apiSendLeadToPartner,
  apiUpdatePatientForPartner,
} from 'endpoints/partner-endpoints';
import {
  apiGetEmailVerificationCode,
  apiGetPhoneVerificationCode,
  apiGetPhoneVerificationSmsStatus,
  apiGetUser,
  apiLoginUser,
  apiLoginUserWithToken,
  apiLogoutUser,
  apiRequestAppointment,
  apiSignupUserAsPatient,
  apiUpdatePatient,
  apiValidatePromoCode,
  apiVerifyEmail,
  apiVerifyPhone,
  apiUploadAppointmentInsuranceCardPhotos,
} from 'endpoints/patient-endpoints';
import {
  GtmDataLayerEvent,
  trackGtmDataLayerEvent,
} from '../../utils/analytics';
import {
  emailMatchClinicResult,
  resetCheckoutFormData,
  updateAppointmentRequested,
  updateCheckoutErrorStatus,
  updateClinic,
  updateClinicPerformanceBadges,
  updateCreatingPatient,
  updateEligiblePartners,
  updateFeaturesForSpecialty,
  updateInsuranceList,
  updateInsuranceListForCanada,
  updateInsuranceListRegion,
  updateLoadingEligiblePartners,
  updateLoadingEmailVerification,
  updateLoadingMatch,
  updateLoadingPhoneVerification,
  updateLoadingPhoneVerificationRequest,
  updateLoadingPhoneVerificationSmsStatus,
  updateLoadingUser,
  updateLoginError,
  updateLoadingLogin,
  updateMatchAndResults,
  updatePatient,
  updatePhoneVerificationSmsStatus,
  updateRequestPhoneVerificationSmsStatusError,
  updateServicesForSpecialty,
  updateUserAndPatient,
  updateValidatePromoCodeError,
} from '../actions';
import {
  CHECKOUT_ERROR_TYPES,
  CREATE_MATCH_AND_RESULTS,
  CREATE_PATIENT,
  EMAIL_MATCH_CLINIC,
  EMPTY_MATCH,
  EMPTY_RESULT,
  GET_ELIGIBLE_PARTNERS,
  GET_INSURANCE_LIST,
  GET_INSURANCE_LIST_FOR_CANADA,
  LOGIN_USER,
  LOGIN_USER_WITH_TOKEN,
  LOGOUT_USER,
  POST_FEEDBACK,
  POST_PARTNER_LEAD,
  RECREATE_MATCH_AND_RESULTS,
  REQUEST_APPOINTMENT,
  REQUEST_CLINIC,
  REQUEST_CLINIC_PERFORMANCE_BADGES,
  REQUEST_EMAIL_VERIFICATION,
  REQUEST_FEATURES_FOR_SPECIALTY,
  REQUEST_MATCH_AND_RESULTS,
  REQUEST_PHONE_VERIFICATION,
  REQUEST_PHONE_VERIFICATION_SMS_STATUS,
  REQUEST_SERVICES_FOR_SPECIALTY,
  REQUEST_USER_AND_PATIENT,
  RESET_MATCHES,
  SAVE_MATCHES,
  UPDATE_MATCH,
  UPDATE_PATIENT_DETAILS,
  VALIDATE_PROMO_CODE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../constants';
import {
  selectDataCheckoutFormData,
  selectDataInsuranceList,
  selectDataInsuranceListForCanada,
  selectDataInsuranceListRegion,
  selectDataMatchId,
  selectDataMatchToken,
  selectDataPatient,
  selectDataUser,
} from '../selectors';
import {
  apiGetInsuranceList,
  apiGetInsuranceListForCanada,
} from '../../endpoints/insurance-endpoints';
import { formatDateOfBirth } from '../../pages/checkout/helpers/insurance-helpers';
import {
  extractErrorDetails,
  extractErrorMessage,
  sendToLogger,
} from './helpers';
import { RequestOffer } from './handlers/offer/request-offer';
import { SagaHandlerRegistry } from './handlers/saga-registry';
import CONFIG from '../../../app.config';
import { getMarketingParamsToQuery } from 'pages/common/helpers/url-helpers';
import { RequestReferralPromotion } from './handlers/offer/request-referral-promotion';

// Sagas

// Attempts to get patient from redux
// If patient doesn't exist fetches user from API
// If authenticated, stores fetched user in redux
function* getPatient() {
  let patient = yield select(selectDataPatient);

  if (!patient) {
    let user = yield select(selectDataUser);

    if (user.authenticated === false) {
      return yield patient;
    }

    user = yield call(apiGetUser);
    yield put(updateUserAndPatient(user));
    patient = yield select(selectDataPatient);
  }

  return yield patient;
}

// Fetch user and patient from API and store them in redux
function* getUserAndPatient({ match }) {
  try {
    yield put(updateLoadingUser(true));
    const user = yield call(apiGetUser);
    if (match && !_.get(user, 'metadata.wizardData')) {
      user.metadata = {};
      user.metadata.wizardData = match.other;
      user.metadata.wizardData.location = match.location;
    }
    yield put(updateUserAndPatient(user));
    yield put(updateLoadingUser(false));
  } catch (error) {
    sendToLogger(error, 'getUserAndPatient', { matchId: match?.id });
  }
}

// Attempts to log user in
// If successful, stores user returned by API in redux
// If unsuccessful, logs error
function* loginUser({ email, password }) {
  try {
    yield put(updateLoadingLogin(true));
    yield put(updateLoginError(null));

    yield call(apiLoginUser, email, password);
    const user = yield call(apiGetUser);

    yield put(
      updateUserAndPatient({
        authenticated: true,
        ...user,
      }),
    );
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    yield put(updateLoginError(errorMessage));
  }
  yield put(updateLoadingLogin(false));
}

// Attempts to log user in with token
// If successful, stores user returned by API in redux
// If unsuccessful, logs error
function* loginUserWithToken({ authParam, deep }) {
  try {
    const base64ToString = Buffer.from(authParam, 'base64').toString();
    const authData = JSON.parse(base64ToString);
    yield put(updateLoginError(null));
    let user = yield call(
      apiLoginUserWithToken,
      authData.email,
      authData.token,
    );
    // Call get user with deep param to populate user deeply
    if (deep) {
      user = yield call(apiGetUser);
    }
    yield put(updateUserAndPatient(user));
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    yield put(updateLoginError(errorMessage));
    sendToLogger(error, 'loginUserWithToken');
  }
}

// Request an appointment on the checkout confirmation step
// Store the appointment in redux to redirect to the patient app
function* requestAppointment({
  providerId,
  requestedSlot,
  requestData,
  offeredAppointmentTypeId,
  insuranceData,
  funnelConfiguration,
  anyProviderSelected,
}) {
  try {
    let user = yield call(apiGetUser);
    const isPartner = !!user.partner;

    if (!isPartner) {
      // If user is not a Partner, Signup as patient
      if (!user.authenticated && user.email) {
        try {
          user = yield call(apiSignupUserAsPatient, user.email);
        } catch (error) {
          sendToLogger(error, 'requestAppointment', {
            providerId,
            requestedSlot,
            offeredAppointmentTypeId,
            funnelConfiguration,
            anyProviderSelected,
          });
        }
        yield put(updateUserAndPatient(user));
      }
    }

    let patient = yield select(selectDataPatient);
    const checkoutFormData = yield select(selectDataCheckoutFormData);
    if (!patient) {
      patient = checkoutFormData;
      patient.dateOfBirth = formatDateOfBirth(
        checkoutFormData.birthYear,
        checkoutFormData.birthMonth,
        checkoutFormData.birthDay,
      );
    }

    const offer = yield select(RequestOffer.value.selector);
    const promotion = yield select(RequestReferralPromotion.value.selector);
    let appointment = null;

    try {
      if (isPartner) {
        appointment = yield call(
          apiRequestAppointmentForPartner,
          user.partner.id,
          providerId,
          patient,
          requestedSlot,
          offer,
          requestData,
          offeredAppointmentTypeId,
          insuranceData,
          promotion,
        );
      } else {
        appointment = yield call(
          apiRequestAppointment,
          providerId,
          patient,
          requestedSlot,
          offer,
          requestData,
          offeredAppointmentTypeId,
          insuranceData,
          funnelConfiguration,
          promotion,
          anyProviderSelected,
        );
      }

      // Upload the insurance card photos if present.
      if (
        checkoutFormData.cardFrontDataUrl ||
        checkoutFormData.cardBackDataUrl
      ) {
        yield call(
          apiUploadAppointmentInsuranceCardPhotos,
          appointment.id,
          checkoutFormData.cardFrontDataUrl,
          checkoutFormData.cardBackDataUrl,
        );
      }

      yield put(updateAppointmentRequested(appointment));

      trackGtmDataLayerEvent(GtmDataLayerEvent.APPOINTMENT_REQUESTED);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      const errorTypes = {
        [CHECKOUT_ERROR_TYPES.SLOT_TAKEN]:
          'This reserved appointment slot is not available.',
        [CHECKOUT_ERROR_TYPES.APPOINTMENT_REQUESTED_ALREADY]:
          'You have already submitted an appointment request with this clinic.',
        [CHECKOUT_ERROR_TYPES.NOT_PATIENT]: 'This patient could not be found.',
        [CHECKOUT_ERROR_TYPES.PROVIDER_NO_ONLINE_BOOKING]:
          'This provider does not have online appointment requests enabled.',
        [CHECKOUT_ERROR_TYPES.PROVIDER_NO_RESERVED_SLOTS]:
          'This provider does not have reserved slot booking enabled.',
      };

      const errorIndexExists = Object.values(errorTypes).findIndex(
        errorType => errorType === errorMessage,
      );

      if (errorIndexExists > -1) {
        yield put(
          updateCheckoutErrorStatus(Object.keys(errorTypes)[errorIndexExists]),
        );
      } else {
        yield put(updateCheckoutErrorStatus(CHECKOUT_ERROR_TYPES.GENERIC));
      }

      // Additional details to help debug
      const errorDetails = extractErrorDetails(error);

      sendToLogger(error, 'requestAppointment', {
        ...errorDetails,
        request_data: JSON.stringify(requestData),
      });
    }
  } catch (error) {
    sendToLogger(error, 'requestAppointment');
  }
}

// Logout the current user and clear the user and patient in redux
function* logoutUser() {
  try {
    yield call(apiLogoutUser);
  } catch (error) {
    sendToLogger(error, 'logoutUser');
  }

  // Set default user value so it's never undefined
  yield put(updateUserAndPatient({ authenticated: false }));
  yield put(updatePatient());
  yield put(resetCheckoutFormData());
}

// Fetch clinic from API and store it in redux
function* requestClinic({ clinicIds }) {
  try {
    const clinics = yield call(apiGetClinics, clinicIds);
    yield put(updateClinic(clinics));
  } catch (error) {
    sendToLogger(error, 'requestClinic', { clinicIds });
  }
}

// Request clinic analytics
function* requestClinicPerformanceBadges({ clinicId }) {
  try {
    const performanceBadges = yield call(
      apiGetClinicPerformanceBadges,
      clinicId,
    );
    const goldPerformanceBadges = performanceBadges
      ? performanceBadges[clinicId].filter(badge => badge.includes('gold'))
      : [];
    yield put(updateClinicPerformanceBadges(goldPerformanceBadges));
  } catch (error) {
    sendToLogger(error, 'requestClinicPerformanceBadges', { clinicId });
  }
}

// Gets patient
// Terminates if no patient found
// Otherwise, gets email verification code in API
function* requestEmailVerification() {
  const patient = yield call(getPatient);

  if (!patient) {
    sendToLogger('No patient exists in redux', 'requestEmailVerification');
    return;
  }

  try {
    yield call(apiGetEmailVerificationCode, patient.id);
  } catch (error) {
    sendToLogger(error, 'requestEmailVerification');
  }
}

// Fetch all features available for a specified specialty and store it in redux
function* requestFeaturesForSpecialty({ specialtyId }) {
  try {
    if (specialtyId) {
      const features = yield call(apiGetFeaturesForSpecialty, specialtyId);
      yield put(updateFeaturesForSpecialty(features, specialtyId));
    }
  } catch (error) {
    sendToLogger(error, 'requestFeaturesForSpecialty', { specialtyId });
  }
}

// Create match and resuts from funnel data
// Store them in redux
function* createMatchAndResults({
  match,
  funnelConfiguration,
  matchResultsPageConfiguration,
}) {
  try {
    yield put(updateLoadingMatch(true));

    const { location, other } = match;
    const { partnerId } = other;
    const args = [];

    if (partnerId) {
      args.push(apiCreateMatchForPartner, partnerId, location, other);
    } else {
      args.push(
        apiCreateMatch,
        location,
        other,
        funnelConfiguration,
        matchResultsPageConfiguration,
      );
    }

    const result = yield call(...args);

    yield put(
      updateMatchAndResults(
        { match: result.match, results: result.results },
        result.matchToken,
      ),
    );
  } catch (error) {
    sendToLogger(error, 'createMatchAndResults', {
      matchId: match.id,
      funnelConfiguration,
      matchResultsPageConfiguration,
    });
    yield put(updateMatchAndResults({ match: { error } }));
  } finally {
    yield put(updateLoadingMatch(false));
  }
}

// Recreate new match and results for returning users
// IMPORTANT: uses their original match id rather than their latest because the API requires this
function* recreateMatchAndResults({
  matchId,
  matchToken,
  authParam,
  createNewMatch,
  offerCode,
}) {
  try {
    yield put(updateLoadingMatch(true));

    if (authParam && typeof authParam === 'string') {
      yield call(loginUserWithToken, { authParam });
    }

    const matchAndResults = yield call(
      apiGetMatch,
      matchId,
      matchToken,
      createNewMatch,
      offerCode,
    );

    const promoCode = _.get(matchAndResults, 'match.other.dmProps.promo_code');

    if (promoCode) {
      const offer = yield call(apiGetOffer, promoCode);
      yield put(RequestOffer.value.update(offer));
    }

    yield put(updateMatchAndResults(matchAndResults, matchToken));
    yield put(updateLoadingMatch(false));
  } catch (error) {
    // Additional details to help debug
    const errorDetails = extractErrorDetails(error);

    sendToLogger(error, 'recreateMatchAndResults', {
      ...errorDetails,
      matchId,
      authParam: !!authParam,
      matchToken: !!matchToken,
      createNewMatch: !!createNewMatch,
    });
  }
}

// Create patient for Partner use
// Store them in redux
function* createPatient({ partnerId, patientData }) {
  try {
    yield put(updateCreatingPatient(true));

    const response = yield call(
      apiCreatePatientForPartner,
      partnerId,
      patientData,
    );
    yield put(updatePatient(response.patient));

    yield put(updateCreatingPatient(false));
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    sendToLogger(error, 'createPatient', { partnerId });
    if (errorMessage === 'A User with the given email already exists.') {
      yield put(
        updateCheckoutErrorStatus(
          CHECKOUT_ERROR_TYPES.EMAIL_ALREADY_REGISTERED,
        ),
      );
    }

    yield put(updatePatient(null));
    yield put(updateCreatingPatient(false));
  }
}

// Request the match and results object
// Store them in redux
function* requestMatchAndResults({ matchId, matchToken, userId, offerCode }) {
  try {
    const getDirectMatch = userId && !matchToken;

    const matchAndResults = getDirectMatch
      ? yield call(apiGetDirectMatch, matchId, userId)
      : yield call(apiGetMatch, matchId, matchToken);

    let promoCode = _.get(matchAndResults, 'match.other.dmProps.promo_code');

    if (getDirectMatch && offerCode && offerCode !== promoCode) {
      promoCode = offerCode;
      yield call(apiUpdateMatch, matchId, matchToken, { offerCode, userId });
    }

    if (promoCode) {
      // TODO update this to call RequestOffer directly
      // yield call(RequestOffer.saga, { payload: promoCode })
      const offer = yield call(apiGetOffer, promoCode);
      yield put(RequestOffer.value.update(offer));
    }

    yield put(updateMatchAndResults(matchAndResults, matchToken));
  } catch (error) {
    sendToLogger(error, 'requestMatchAndResults', {
      matchId,
      matchToken,
    });
    const errorURL = !matchToken
      ? `${CONFIG.SEARCH_APP_URL}?${getMarketingParamsToQuery()}`
      : `/error?matchId=${matchId}&matchToken=${matchToken}`;

    window.location.assign(errorURL);
  }
}

// Gets patient
// Terminates if no patient found
// Otherwise, gets phone verification code in API
function* requestPhoneVerification({ verificationType }) {
  yield put(updateLoadingPhoneVerificationRequest(true));
  const patient = yield call(getPatient);

  if (!patient) {
    sendToLogger('No patient exists in redux', 'requestPhoneVerification');
    return;
  }

  try {
    yield call(apiGetPhoneVerificationCode, patient.id, verificationType);
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    sendToLogger(errorMessage, 'requestPhoneVerification');
  } finally {
    yield put(updateLoadingPhoneVerificationRequest(false));
  }
}

// Gets patient
// Terminates if no patient found
// Gets patient phone verification sms delivery status
function* requestPhoneVerificationSmsStatus() {
  yield put(updateLoadingPhoneVerificationSmsStatus(true));
  const patient = yield call(getPatient);

  if (!patient) {
    sendToLogger(
      'No patient exists in redux',
      'requestPhoneVerificationSmsStatus',
    );
    return;
  }

  try {
    const status = yield call(apiGetPhoneVerificationSmsStatus, patient.id);

    yield put(updatePhoneVerificationSmsStatus(status));
    yield put(updateRequestPhoneVerificationSmsStatusError(null));
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    yield put(updateRequestPhoneVerificationSmsStatusError(errorMessage));
    sendToLogger(error, 'requestPhoneVerificationSmsStatus');
  } finally {
    yield put(updateLoadingPhoneVerificationSmsStatus(false));
  }
}

// Fetch all services available for a specified specialty and store it in redux
function* requestServicesForSpecialty({ specialtyId }) {
  try {
    const services = yield call(apiGetServicesForSpecialty, specialtyId);
    yield put(updateServicesForSpecialty(services, specialtyId));
  } catch (error) {
    sendToLogger(error, 'requestServicesForSpecialty', { specialtyId });
  }
}

// Gets patient from redux
// Terminates if no patient found
// Otherwise, gets email verification code in API
function* saveMatches({ email }) {
  const matchId = yield select(selectDataMatchId);
  const matchToken = yield select(selectDataMatchToken);
  let user = yield select(selectDataUser);

  // Signup user as patient
  if (!user.authenticated) {
    try {
      user = yield call(apiSignupUserAsPatient, email);
      yield apiUpdateMatch(matchId, matchToken); // Tie match to user
      yield put(updateUserAndPatient(user));
    } catch (error) {
      sendToLogger(error, 'saveMatches');
    }
  }

  // Send match email
  try {
    yield apiSendMatchEmail(matchId, matchToken, email);
  } catch (error) {
    sendToLogger(error, 'saveMatches');
  }
}

// Clears matches from redux
function* resetMatches() {
  try {
    yield put(
      updateMatchAndResults({ match: EMPTY_MATCH, results: [EMPTY_RESULT] }),
    );
  } catch (error) {
    sendToLogger(error, 'resetMatches');
  }
}

function* updateMatch({ matchId, matchToken, experimentData }) {
  try {
    if (matchId && matchToken) {
      yield call(apiUpdateMatch, matchId, matchToken, { experimentData });

      if (experimentData) {
        yield put(updateLoadingMatch(true));
        yield call(requestMatchAndResults, { matchId, matchToken });
        yield put(updateLoadingMatch(false));
      }
    }
  } catch (error) {
    sendToLogger(error, 'updateMatch', {
      matchId,
      matchToken,
      experimentData,
    });
  }
}

// Update the patient in our database with the details
// provided and update the patient in redux with the new one
// If unsuccessful, will update the patient in redux to be
// undefined
function* updatePatientInApi({ patientDetails, autoVerify }) {
  yield put(updateLoadingUser(true));
  let patient = yield select(selectDataPatient);

  const patientDetailsUpdated = Object.assign(
    {},
    _.get(patient, 'metadata'),
    patientDetails,
  );

  try {
    if (_.get(patientDetails, 'partnerId')) {
      patient = yield call(
        apiUpdatePatientForPartner,
        patientDetails.partnerId,
        patientDetails.id,
        patientDetailsUpdated,
      );
    } else {
      patient = yield call(
        apiUpdatePatient,
        patient.id,
        patientDetailsUpdated,
        autoVerify,
      );
    }

    yield put(updateCheckoutErrorStatus(null));
    yield put(updatePatient(patient, patientDetailsUpdated));
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    if (
      errorMessage.includes(
        'Could not use specified `mobilePhone`. Value failed custom validation.',
      )
    ) {
      yield put(updateCheckoutErrorStatus(CHECKOUT_ERROR_TYPES.PHONE_INVALID));
    } else {
      yield put(updateCheckoutErrorStatus(CHECKOUT_ERROR_TYPES.GENERIC));
    }
    if (errorMessage.includes('patient exists with this email')) {
      yield call(logoutUser);
    } else {
      sendToLogger(error, 'updatePatientInApi');
    }
  }

  yield put(updateLoadingUser(false));
}

export function* validatePromoCode({ code }) {
  yield put(updateValidatePromoCodeError(undefined));
  try {
    const validatedOffer = yield call(apiValidatePromoCode, code);
    if (validatedOffer.offerType) {
      yield put(RequestOffer.value.update(validatedOffer.offerType));
    } else {
      yield put(
        updateValidatePromoCodeError('Sorry! That promo code is not valid.'),
      );
    }
  } catch (error) {
    sendToLogger(error, 'validatePromoCode');
  }
}

// Attempts to verify a patient's email using verification code
// If unsuccessful, logs error to Logger
function* verifyEmail({ code }) {
  let patient = yield select(selectDataPatient);
  yield put(updateLoadingEmailVerification(true));

  try {
    patient = yield call(apiVerifyEmail, patient.id, code);
    yield put(updatePatient(patient));
  } catch (error) {
    sendToLogger(error, 'verifyEmail');
  }

  yield put(updateLoadingEmailVerification(false));
}

// Attempts to verify a patient's phone using verification code
// If unsuccessful, logs error to Logger
function* verifyPhone({ code }) {
  let patient = yield select(selectDataPatient);
  yield put(updateLoadingPhoneVerification(true));

  try {
    patient = yield call(apiVerifyPhone, patient.id, code);
    yield put(updatePatient(patient));
  } catch (error) {
    sendToLogger(error, 'verifyPhone');
  }

  yield put(updateLoadingPhoneVerification(false));
}

function* postFeedback({ source, data }) {
  try {
    yield call(apiPostFeedback, source, data);
  } catch (error) {
    sendToLogger(error, 'postFeedback');
  }
}

// Fetch all partners that are eligible to send leads to based on patient address
function* requestEligiblePartners({ location }) {
  yield put(updateLoadingEligiblePartners(true));
  try {
    const response = yield call(apiGetLeadPartners, location);
    yield put(updateEligiblePartners(response));
  } catch (error) {
    sendToLogger(error, 'requestEligiblePartners');
    yield put(updateEligiblePartners([]));
  }
  yield put(updateLoadingEligiblePartners(false));
}

// Sends lead to Partner
function* sendPartnerLead({
  partnerId,
  patientInformation,
  location,
  leadInformation,
}) {
  try {
    yield call(
      apiSendLeadToPartner,
      partnerId,
      patientInformation,
      location,
      leadInformation,
    );
  } catch (error) {
    sendToLogger(error, 'sendPartnerLead');
    yield put(updatePatient(null));
  }
}

function* getInsuranceList({ region }) {
  let insuranceList = yield select(selectDataInsuranceList);
  const insuranceListRegion = yield select(selectDataInsuranceListRegion);

  if (!insuranceList?.length || insuranceListRegion !== region) {
    insuranceList = yield call(apiGetInsuranceList, region);
    yield put(updateInsuranceList(insuranceList));
    yield put(updateInsuranceListRegion(region));
  }

  return yield insuranceList;
}

function* getInsuranceListForCanada() {
  let insuranceListForCanada = yield select(selectDataInsuranceListForCanada);

  if (!insuranceListForCanada?.length) {
    insuranceListForCanada = yield call(apiGetInsuranceListForCanada);
    yield put(updateInsuranceListForCanada(insuranceListForCanada));
  }

  return yield insuranceListForCanada;
}

/**
 * Initiates an email sending with the given match result clinic.
 *
 * @param matchId - match id.
 * @param clinicId - clinic id.
 * @param source - source that is used for utm_source parameter in the email link.
 */
function* emailMatchClinic({ matchId, clinicId, source }) {
  try {
    yield put(
      emailMatchClinicResult({
        status: 'waiting',
      }),
    );
    yield call(apiEmailMatchClinic, matchId, clinicId, source);
    yield put(
      emailMatchClinicResult({
        status: 'completed',
        error: null,
      }),
    );
  } catch (error) {
    yield put(
      emailMatchClinicResult({
        status: 'completed',
        error: extractErrorMessage(error),
      }),
    );
  }
}

// Exports
const clinicSaga = [
  takeLatest(REQUEST_CLINIC, requestClinic),
  takeLatest(REQUEST_FEATURES_FOR_SPECIALTY, requestFeaturesForSpecialty),
  takeLatest(REQUEST_SERVICES_FOR_SPECIALTY, requestServicesForSpecialty),
  takeLatest(REQUEST_CLINIC_PERFORMANCE_BADGES, requestClinicPerformanceBadges),
];

const resultsSaga = [
  takeLatest(LOGIN_USER, loginUser),
  takeLatest(LOGIN_USER_WITH_TOKEN, loginUserWithToken),
  takeLatest(LOGOUT_USER, logoutUser),
  takeLatest(REQUEST_APPOINTMENT, requestAppointment),
  takeLatest(REQUEST_EMAIL_VERIFICATION, requestEmailVerification),
  takeLatest(CREATE_MATCH_AND_RESULTS, createMatchAndResults),
  takeLatest(RECREATE_MATCH_AND_RESULTS, recreateMatchAndResults),
  takeLatest(CREATE_PATIENT, createPatient),
  takeLatest(REQUEST_MATCH_AND_RESULTS, requestMatchAndResults),
  takeLatest(REQUEST_PHONE_VERIFICATION, requestPhoneVerification),
  takeLatest(
    REQUEST_PHONE_VERIFICATION_SMS_STATUS,
    requestPhoneVerificationSmsStatus,
  ),
  takeLatest(REQUEST_USER_AND_PATIENT, getUserAndPatient),
  takeLatest(SAVE_MATCHES, saveMatches),
  takeLatest(RESET_MATCHES, resetMatches),
  takeLatest(UPDATE_MATCH, updateMatch),
  takeLatest(UPDATE_PATIENT_DETAILS, updatePatientInApi),
  takeLatest(VERIFY_EMAIL, verifyEmail),
  takeLatest(VERIFY_PHONE, verifyPhone),
  takeLatest(POST_FEEDBACK, postFeedback),
  takeLatest(EMAIL_MATCH_CLINIC, emailMatchClinic),
];

const offerSaga = [takeLatest(VALIDATE_PROMO_CODE, validatePromoCode)];

const partnerSaga = [
  takeLatest(GET_ELIGIBLE_PARTNERS, requestEligiblePartners),
  takeLatest(POST_PARTNER_LEAD, sendPartnerLead),
];

const insuranceSaga = [
  takeLatest(GET_INSURANCE_LIST, getInsuranceList),
  takeLatest(GET_INSURANCE_LIST_FOR_CANADA, getInsuranceListForCanada),
];

export default function* watchAll() {
  const sagaHandlerRegistry = new SagaHandlerRegistry();
  const actionHandlersSaga = sagaHandlerRegistry.getHandlers().map(
    // eslint-disable-next-line redux-saga/yield-effects
    handler => takeLatest(handler.actionName, handler.saga),
  );

  yield all([
    ...clinicSaga,
    ...resultsSaga,
    ...offerSaga,
    ...partnerSaga,
    ...insuranceSaga,
    ...actionHandlersSaga,
  ]);
}
