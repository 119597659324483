// 3rd Party
import _ from 'lodash';

// Other
import { MARKER_ICONS } from '../constants/google-maps-constants';
import { colors } from 'common/styles/style-constants';
import { combineAllReviews } from './common-helpers';

// Exports
export const getMapBoundsFromMarkers = markers => {
  let north;
  let east;
  let south;
  let west;

  markers.forEach(marker => {
    if (north === undefined || marker.position.lat > north) {
      north = marker.position.lat;
    }
    if (east === undefined || marker.position.lng > east) {
      east = marker.position.lng;
    }
    if (south === undefined || marker.position.lat < south) {
      south = marker.position.lat;
    }
    if (west === undefined || marker.position.lng < west) {
      west = marker.position.lng;
    }
  });

  return { north, east, south, west };
};

export const wideMapBounds = markers => {
  const marker = markers[0];

  // Based on the zoom amount needed
  const latOffset = 0.001;
  const lngOffset = 0.03;

  const north = marker.position.lat + latOffset;
  const east = marker.position.lng + lngOffset;
  const south = marker.position.lat - latOffset;
  const west = marker.position.lng - lngOffset;

  return { north, east, south, west };
};

export const getMapBounds = (markers, fallbackPosition) => {
  const mapBoundsHandlers = {
    0: () => wideMapBounds([{ position: fallbackPosition }]),
    1: () => wideMapBounds(markers),
  };

  return (
    mapBoundsHandlers[markers.length]?.() ?? getMapBoundsFromMarkers(markers)
  );
};

export const getMarkerIcons = result => {
  let icon = MARKER_ICONS.blankBlue;
  let iconHighlighted = MARKER_ICONS.blankWhiteBlue;

  if (result.isInstantBook) {
    icon = MARKER_ICONS.blankCoralInstant;
    iconHighlighted = MARKER_ICONS.blankWhiteCoralInstant;
  }

  return { icon, iconHighlighted };
};

const getMapMarker = ({
  mapItem,
  icon,
  highlightedIcon,
  index,
  isUserLocation = false,
}) => {
  const reviews = combineAllReviews(mapItem);
  const primaryPhoto = _.get(mapItem, 'providers[0].doctor.primaryPhoto') || {};

  return {
    id: mapItem.id ?? 'user-location',
    position: {
      lat: mapItem.latitude,
      lng: mapItem.longitude,
    },
    icon,
    highlighted: {
      icon: highlightedIcon ?? icon,
      label: {
        color:
          !isUserLocation && mapItem.isInstantBook ? colors.coral : colors.blue,
        text: `${index + 1}`,
      },
    },
    isUserLocation,
    ...(!isUserLocation && {
      info: {
        image: {
          name: primaryPhoto.name,
          extension: primaryPhoto.extension,
        },
        reviewsCount: reviews.length,
        reviews,
        text: mapItem.name,
      },
      label: {
        color: colors.white,
        text: `${index + 1}`,
      },
    }),
    ...(isUserLocation && {
      info: {
        text: 'Your Location',
      },
      address: mapItem.formattedAddress,
    }),
  };
};

export const getResultsMapMarkers = results =>
  results.map((result, index) => {
    const icons = getMarkerIcons(result);
    return getMapMarker({
      mapItem: result,
      icon: icons.icon,
      highlightedIcon: icons.iconHighlighted,
      index,
    });
  });

export const getClinicMapMarker = clinic => {
  const icon = MARKER_ICONS.coralDotted;
  return [
    getMapMarker({ mapItem: clinic, icon, highlightedIcon: icon, index: 0 }),
  ];
};

export const getUserLocationMapMarker = userLocation => {
  const icon = MARKER_ICONS.locationPin;
  return [getMapMarker({ mapItem: userLocation, icon, isUserLocation: true })];
};

export const getSingleMarkerBounds = marker => {
  const pos = marker.position;
  const latOffset = 0.001;
  const lngOffset = 0.03;

  return {
    north: pos.lat + latOffset,
    east: pos.lng + lngOffset,
    south: pos.lat - latOffset,
    west: pos.lng - lngOffset,
  };
};

export const getResultBounds = (mapBounds, marker) => {
  const pos = marker.position;
  const latOffset = (mapBounds.north - mapBounds.south) / 2;
  const lngOffset = (mapBounds.east - mapBounds.west) / 2;

  return {
    north: pos.lat + latOffset,
    east: pos.lng + lngOffset,
    south: pos.lat - latOffset,
    west: pos.lng - lngOffset,
  };
};
