import produce from 'immer';

import {
  LIST_TAB,
  UPDATE_HIGHLIGHTED_RESULT,
  UPDATE_TAB,
} from './ui-constants';

export const initialState = {
  highlightedResultIndex: undefined,
  showTrustedBox: true,
  tab: LIST_TAB,
};

/* eslint-disable no-param-reassign */

function resultsUiReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_HIGHLIGHTED_RESULT:
        draft.highlightedResultIndex = action.highlightedResultIndex;
        break;
      case UPDATE_TAB:
        draft.tab = action.tab;
        break;
      default:
    }
  });
}

export default resultsUiReducer;
