// TODO use the API to get the list of markets
// eslint-disable-next-line no-unused-vars
const ALL_CANADIAN_MARKETS = [
  'toronto',
  'ottawa',
  'kwc',
  'hamilton',
  'calgary',
  'vancouver',
];

// TODO use the API to get the list of markets
// eslint-disable-next-line no-unused-vars
const ALL_US_MARKETS = [
  'chicago',
  'san diego',
  'bay area',
  'los angeles',
  'denver',
  'austin',
  'houston',
  'miami',
  'phoenix',
  'dallas',
  'new york city',
  'las vegas',
];

// eslint-disable-next-line no-unused-vars
const CALIFORNIA_MARKETS = ['bay area', 'san diego', 'los angeles'];

export const VARIANTS = Object.freeze({
  CONTROL: 'CONTROL',
  VARIANT: 'VARIANT',
});

// Experiment Documentation: https://www.notion.so/opencare/Creating-an-Experiment-188133bf427f418c9de367ef3586db88?pvs=4

// TODO: Allow more than one experiment in match metadata
/**
 * @type {{
 *   [key: string]: {
 *     name: string,
 *     markets?: string[],
 *   }
 * }}
 *
 * name: Short name of the experiment. Must match the name in ExperimentConfigs in the API
 * markets: List of markets where the experiment should be run
 */
export const EXPERIMENTS = {
  // Temporarily disabled in the API and where useExperiment is called in ResultsPage.js until we come up with a better design
  VIDEO_EXPERIMENT: {
    name: 'video',
    markets: ALL_CANADIAN_MARKETS,
  },
  OFFER_MODAL_DESIGN_EXPERIMENT: {
    name: 'offer-modal-design-iteration-2',
    markets: ALL_CANADIAN_MARKETS,
  },
  AVAILABLE_SERVICES: {
    name: 'available-services',
    markets: ALL_CANADIAN_MARKETS,
  },
};
