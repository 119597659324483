// 3rd Party
import produce from 'immer';
import localforage from 'localforage';
import { get } from 'lodash';
import { persistReducer } from 'redux-persist';

// Other
import {
  CLEAR_CLINIC,
  EMPTY_APPOINTMENT,
  EMPTY_CLINIC,
  EMPTY_INSURANCE,
  EMPTY_MATCH,
  EMPTY_OFFER,
  EMPTY_RESULT,
  RESET_CHECKOUT_FORM_DATA,
  RESET_MATCH_INSURANCE,
  UPDATE_APPOINTMENT_REQUESTED,
  UPDATE_BOOKING_WIDGET_SLOT,
  UPDATE_CHECKOUT_ERROR_STATUS,
  UPDATE_CHECKOUT_FORM_DATA,
  UPDATE_CJ_TAGS,
  UPDATE_CLINIC,
  UPDATE_CLINIC_PERFORMANCE_BADGES,
  UPDATE_CREATING_PATIENT,
  UPDATE_DIRECT_TO_PROFILE_LINK,
  UPDATE_DM_PROPS,
  UPDATE_ELIGIBLE_PARTNERS,
  UPDATE_EXCLUDE_PROVIDER_ID,
  UPDATE_EXISTING_USER_EMAIL,
  UPDATE_FEATURES_FOR_SPECIALTY,
  UPDATE_INSURANCE_PROVIDER_AND_PLAN,
  UPDATE_INSURANCE_VERIFICATION_RESPONSE,
  UPDATE_LOADING_ELIGIBLE_PARTNERS,
  UPDATE_LOADING_EMAIL_VERIFICATION,
  UPDATE_LOADING_MATCH,
  UPDATE_LOADING_PHONE_VERIFICATION,
  UPDATE_LOADING_PHONE_VERIFICATION_REQUEST,
  UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS,
  UPDATE_LOADING_USER,
  UPDATE_LOGIN_ERROR,
  UPDATE_LOADING_LOGIN,
  UPDATE_MATCH_AND_RESULTS,
  UPDATE_ONGOING_CARE_NO_MATCH,
  UPDATE_REASON_NO_MATCH,
  UPDATE_MEMBER_ID,
  UPDATE_PATIENT,
  UPDATE_PHONE_VERIFICATION_SMS_STATUS,
  UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR,
  UPDATE_SELECTED_APPOINTMENT,
  UPDATE_SELECTED_RESULT,
  UPDATE_SERVICES_FOR_SPECIALTY,
  UPDATE_SPECIFIC_AVAILABILITIES,
  UPDATE_USER_AND_PATIENT,
  UPDATE_VALIDATE_PROMO_CODE_ERROR,
  UPDATE_INSURANCE_LIST,
  UPDATE_INSURANCE_LIST_REGION,
  UPDATE_INSURANCE_LIST_FOR_CANADA,
  UPDATE_PYPESTREAM_READY,
  EMAIL_MATCH_CLINIC_RESULT,
} from './constants';
import { StateHandlerRegistry } from './state/registry';

export const initialState = {
  appointment: EMPTY_APPOINTMENT,
  checkoutFormData: {
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    employer: undefined,
    isPrimaryHolder: undefined,
    memberId: undefined,
    groupNumber: undefined,
    plan: undefined,
    planId: undefined,
    provider: undefined,
    providerId: undefined,
    socialAssistance: undefined,
    cardFrontDataUrl: undefined,
    cardBackDataUrl: undefined,
  },
  checkoutFormErrorStatus: null,
  clinic: EMPTY_CLINIC,
  clinicPerformanceBadges: [],
  excludeProviderId: undefined,
  featuresForSpecialty: {
    features: [],
    specialtyId: undefined,
  },
  loadingEmailVerification: false,
  loadingPhoneVerification: false,
  loadingPhoneVerificationRequest: false,
  loadingUser: false,
  loadingMatch: false,
  loadingPhoneVerificationSmsStatus: false,
  match: EMPTY_MATCH,
  matchToken: '',
  offer: EMPTY_OFFER,
  patient: undefined,
  profileLink: undefined,
  bookingWidgetSlot: undefined,
  reasonNoMatch: undefined,
  requestedAppointment: {
    providerId: undefined,
    slotId: undefined,
    provider: undefined,
  },
  // Initialize with empty objects so cards render
  results: [
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
    EMPTY_RESULT,
  ],
  selectedResultIndex: undefined,
  servicesForSpecialty: {
    services: [],
    specialtyId: undefined,
  },
  validatePromoCodeError: undefined,
  user: { authenticated: false },
  cjTags: undefined,
  phoneVerificationSmsStatus: undefined,
  phoneVerificationSmsStatusError: undefined,
  insuranceList: undefined,
  insuranceListRegion: undefined,
  pypestreamReady: false,
};

/* eslint-disable no-param-reassign */

function dataReducer(state = initialState, action) {
  return produce(state, draft => {
    const stateHandler = StateHandlerRegistry.singleton.getStateHandler(
      action.type,
    );

    if (stateHandler) {
      stateHandler.handleAction(draft, action);
      return;
    }

    switch (action.type) {
      // If the clinic ID specified is not the same as the
      // ID of the clinic in redux, clear the clinic in redux
      case CLEAR_CLINIC: {
        if (draft.clinic.id !== action.clinicId) {
          draft.clinic = EMPTY_CLINIC;
        }
        break;
      }

      case UPDATE_APPOINTMENT_REQUESTED: {
        draft.appointment = action.appointment;
        break;
      }

      // Update checkout form data in redux
      case UPDATE_CHECKOUT_FORM_DATA: {
        if (action.formData) {
          draft.checkoutFormData = {
            ...draft.checkoutFormData,
            ...action.formData,
          };
        }
        break;
      }

      // reset the checkout form data in redux if the user logs out on the patient step or checkout flow
      case RESET_CHECKOUT_FORM_DATA: {
        draft.checkoutFormData = initialState.checkoutFormData;
        break;
      }

      // remove any persisted insurance info from redux
      // used for direct clinic profile links, as the match object has to be persisted between sessions to maintain the matchId & token
      // without resetting this, there can be a bug where the wrong insurance info is submitted for users who reach the clinic profile without going through the recommendations funnel if there is already insurance info in redux
      case RESET_MATCH_INSURANCE: {
        draft.match.other.insurance = EMPTY_INSURANCE;
        break;
      }

      // Set the redux clnic to be the response from the API
      // Note API returns an array of clinics responding to the
      // array of IDs requested, but we only ever ask for one
      case UPDATE_CLINIC: {
        draft.clinic = action.payload[0]; // eslint-disable-line
        break;
      }

      case UPDATE_CLINIC_PERFORMANCE_BADGES: {
        draft.clinicPerformanceBadges = action.payload;
        break;
      }

      // Set a flag in redux that the user got to the clinic profile from a direct link
      // This removes paths for the users to return to the results list
      case UPDATE_DIRECT_TO_PROFILE_LINK: {
        draft.profileLink = action.directToProfile;
        break;
      }

      // updates the match dmProps in redux when added as query params
      // used for direct profile links from the friendsProviderVariant of the invite page
      // those users reach the clinic profile without a match, but need these values added to redux to properly attach the promos
      case UPDATE_DM_PROPS: {
        draft.match.other.dmProps.promo_code = action.promoCode;
        draft.match.other.dmProps.referral_code = action.referralCode;
        draft.match.other.dmProps.referral_promo = action.referralPromo;
        break;
      }

      case UPDATE_BOOKING_WIDGET_SLOT: {
        draft.bookingWidgetSlot = {
          providerId: action.providerId,
          date: action.date,
          time: action.time,
        };
        break;
      }

      case UPDATE_EXCLUDE_PROVIDER_ID: {
        draft.excludeProviderId = action.providerId;
        break;
      }

      // If the entered email on the patient step in the checkout funnel is already in our system, update the checkoutFormData in redux to allow the user to continue to the next step
      case UPDATE_EXISTING_USER_EMAIL: {
        draft.checkoutFormData.existingUserEmail = true;
        break;
      }

      // Update the patient creation request state in redux
      case UPDATE_CREATING_PATIENT: {
        draft.creatingPatient = action.creatingPatient;
        break;
      }

      // Update the email verification loading state in redux
      case UPDATE_LOADING_EMAIL_VERIFICATION: {
        draft.loadingEmailVerification = action.loading;
        break;
      }

      // Update the phone verification loading state in redux
      case UPDATE_LOADING_PHONE_VERIFICATION: {
        draft.loadingPhoneVerification = action.loading;
        break;
      }

      // Update the phone verification request loading state in redux
      case UPDATE_LOADING_PHONE_VERIFICATION_REQUEST: {
        draft.loadingPhoneVerificationRequest = action.loading;
        break;
      }

      // Update the user loading state in redux
      case UPDATE_LOADING_USER: {
        draft.loadingUser = action.loadingUser;
        break;
      }

      // Update the user login state in redux
      case UPDATE_LOADING_LOGIN: {
        draft.loadingLogin = action.loadingLogin;
        break;
      }

      case UPDATE_LOADING_MATCH: {
        draft.loadingMatch = action.loadingMatch;
        break;
      }

      // Set the match and results fields in redux
      // Also sets insurance data in checkout form data object
      case UPDATE_MATCH_AND_RESULTS: {
        draft.match = action.matchAndResults.match;
        draft.matchToken = action.matchToken;
        draft.results = action.matchAndResults.results;

        const insurance = action.matchAndResults.match?.other?.insurance ?? {};

        const {
          employer,
          isPrimaryHolder,
          plan,
          planId,
          provider,
          providerId,
          socialAssistance,
        } = insurance;

        draft.checkoutFormData = {
          ...draft.checkoutFormData,
          employer,
          isPrimaryHolder,
          plan,
          planId,
          provider,
          providerId,
          socialAssistance,
        };
        break;
      }

      case UPDATE_ONGOING_CARE_NO_MATCH: {
        draft.ongoingCareNoMatch = action.ongoingCareNoMatch;
        break;
      }

      case UPDATE_REASON_NO_MATCH: {
        draft.reasonNoMatch = action.reasonNoMatch;
        break;
      }

      // Update the member id from the checkout flow
      case UPDATE_MEMBER_ID: {
        draft.match.other.insurance.memberId = action.memberId;
        break;
      }

      case UPDATE_INSURANCE_PROVIDER_AND_PLAN: {
        draft.match.other.insurance.provider = action.newInsurance.provider;
        draft.match.other.insurance.providerId = action.newInsurance.providerId;
        draft.match.other.insurance.plan = action.newInsurance.plan;
        draft.match.other.insurance.planId = action.newInsurance.planId;
        break;
      }

      case UPDATE_INSURANCE_VERIFICATION_RESPONSE: {
        draft.match.other.insurance.verified =
          action.verificationResponse.success;
        draft.match.other.insurance.verificationProviderSupported =
          action.verificationResponse.verificationProviderSupported;
        draft.match.other.insurance.insuranceFeedbackId =
          action.verificationResponse.insuranceFeedbackId;
        break;
      }

      // Update the patient in redux
      case UPDATE_PATIENT: {
        draft.patient = action.patient;
        break;
      }

      case UPDATE_PHONE_VERIFICATION_SMS_STATUS: {
        draft.phoneVerificationSmsStatus = action.phoneVerificationSmsStatus;
        break;
      }

      // Update the selected appointment in redux
      case UPDATE_SELECTED_APPOINTMENT: {
        draft.requestedAppointment.providerId = action.providerId;
        draft.requestedAppointment.slotId = action.slotId;
        draft.requestedAppointment.provider = action.provider;
        draft.requestedAppointment.anyProviderSelected =
          action.anyProviderSelected;
        break;
      }

      // Update the selected result index
      case UPDATE_SELECTED_RESULT: {
        draft.selectedResultIndex = action.selectedResultIndex;
        break;
      }

      // Update the list of services available for the specified specialty
      case UPDATE_SERVICES_FOR_SPECIALTY: {
        draft.servicesForSpecialty.services = action.services;
        draft.servicesForSpecialty.specialtyId = action.specialtyId;
        break;
      }

      // Update the list of features available for the specified specialty
      case UPDATE_FEATURES_FOR_SPECIALTY: {
        draft.featuresForSpecialty.features = action.features;
        draft.featuresForSpecialty.specialtyId = action.specialtyId;
        break;
      }
      // Update the user's match's specific availability
      case UPDATE_SPECIFIC_AVAILABILITIES: {
        draft.match.other.specificAvailabilities =
          action.specificAvailabilities;
        break;
      }

      // Update the user in redux and update the patient
      // in redux if it exists in the user object
      case UPDATE_USER_AND_PATIENT: {
        const patient = get(action.user, 'editable.patients[0]');
        if (patient) {
          // TODO: will there be a case where there already is metadata that we dont want to overwrite?
          patient.metadata = action.user.metadata;
          draft.patient = patient;
        }
        draft.user = action.user;
        break;
      }

      case UPDATE_CHECKOUT_ERROR_STATUS: {
        draft.checkoutFormErrorStatus = action.status;
        break;
      }

      case UPDATE_LOGIN_ERROR: {
        draft.loginFormError = action.error;
        break;
      }

      case UPDATE_VALIDATE_PROMO_CODE_ERROR: {
        draft.validatePromoCodeError = action.error;
        break;
      }

      case UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR: {
        draft.phoneVerificationSmsStatusError = action.error;
        break;
      }

      case UPDATE_CJ_TAGS: {
        draft.cjTags = {
          ...draft.cjTags,
          ...action.cjTags,
        };
        break;
      }

      // Update the eligible partners loading state in redux
      case UPDATE_LOADING_ELIGIBLE_PARTNERS: {
        draft.loadingEligiblePartners = action.loadingEligiblePartners;
        break;
      }

      case UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS: {
        draft.loadingPhoneVerificationSmsStatus = action.loading;
        break;
      }

      // Update the eligible partners state in redux
      case UPDATE_ELIGIBLE_PARTNERS: {
        draft.eligiblePartners = action.eligiblePartners;
        break;
      }

      case UPDATE_INSURANCE_LIST: {
        draft.insuranceList = action.insuranceList;
        break;
      }

      case UPDATE_INSURANCE_LIST_REGION: {
        draft.insuranceListRegion = action.region;
        break;
      }

      case UPDATE_INSURANCE_LIST_FOR_CANADA: {
        draft.insuranceListForCanada = action.insuranceListForCanada;
        break;
      }

      case UPDATE_PYPESTREAM_READY: {
        draft.pypestreamReady = action.pypestreamReady;
        break;
      }

      case EMAIL_MATCH_CLINIC_RESULT: {
        draft.emailMatchClinicResult = action.result;
        break;
      }

      default: {
        break;
      }
    }
  });
}

// This is using the redux-persist library to maintain our redux store on reload of the page
// If there is a new matchId & matchToken (from a new search), the redux store will be updated and saved to indexedDb
// Otherwise, on refresh of the page the redux store data will persist
const persistConfig = {
  key: 'data',
  storage: localforage,
  blacklist: [
    'user',
    'patient',
    'clinic',
    'checkoutFormData',
    'appointment',
    'pypestreamReady',
  ],
};

const persistedDataReducer = persistReducer(persistConfig, dataReducer);

export default persistedDataReducer;
