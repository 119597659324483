import _, { uniqBy, orderBy } from 'lodash';
import googleReviews from 'assets/google-review-data.json';
import moment from 'moment';
import { COUNTRY } from '../../../common/constants';
import CONFIG from '../../../../app.config';

export const reviewRatingMean = reviews => {
  if (reviews) {
    let total = 0;

    for (let i = 0; i < reviews.length; i += 1) {
      total += reviews[i].overallRating;
    }

    const sum = total / reviews.length;

    return Math.round(sum * 10) / 10;
  }

  return 0;
};

export const combineAllReviews = clinic => {
  const reviews = [...(_.get(clinic, 'externalReviews') || [])];

  const providers = _.get(clinic, 'providers');

  if (providers) {
    for (let i = 0; i < providers.length; i += 1) {
      reviews.push(...providers[i].doctor.reviews);
    }

    reviews.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    return reviews;
  }

  return [];
};

export const formatAverageRating = averageRating =>
  averageRating && _.isNumber(averageRating)
    ? (Math.round(averageRating * 10) / 10).toFixed(1)
    : 0;

export const getInNetworkStatus = (planId, insurancePlans) =>
  insurancePlans && insurancePlans.includes(planId);

export const getAggregateReviewData = ({ clinicId, opencareReviews }) => {
  const opencareReviewsCount = opencareReviews.length;
  const opencareReviewsRating = reviewRatingMean(opencareReviews);

  const googleReviewsCount = _.get(googleReviews[clinicId], 'count', 0);
  const googleReviewsRating = _.get(googleReviews[clinicId], 'rating', 0);

  const aggregateReviewsCount = opencareReviewsCount + googleReviewsCount;
  // Computed weighted average of ratings from both sources
  const aggregateReviewsRating =
    (opencareReviewsRating * opencareReviewsCount +
      googleReviewsRating * googleReviewsCount) /
    aggregateReviewsCount;

  return {
    count: aggregateReviewsCount,
    rating: aggregateReviewsRating,
    sources:
      Math.min(opencareReviewsCount, 1) + Math.min(googleReviewsCount, 1),
  };
};

export const filteredProviderPhotos = result =>
  (_.get(result, 'providers') || [])
    .filter(provider => provider.onlineBooking)
    .map(provider => ({
      ...provider.doctor.primaryPhoto,
      altText: provider.doctor.name,
    }));

export const getResultLocation = result =>
  _.get(result, 'address.customNeighborhood') ||
  _.get(result, 'address.neighborhood') ||
  _.get(result, 'address.locality');

export const displayMiles = result =>
  _.get(result, 'address.country') !== COUNTRY.canada;

export const convertMetersToMiles = meters => (meters / 1609).toFixed(1);

export const convertMetersToKilometers = meters => (meters / 1000).toFixed(1);

export const getAndFilterLanguages = providers => {
  if (!providers) return [];
  const languagesSpoken = [];
  providers.map(provider =>
    provider.doctor.languages.map(language =>
      languagesSpoken.push(language.name),
    ),
  );
  return [...new Set(languagesSpoken)].sort();
};

/**
 * Groups the given slots by days for the Match Results Time Slots widget.
 */
export const groupSlotsByDayForResults = result => {
  const slots = _.get(result, 'slots', []);
  const maxDate = slots
    .map(slot => slot.date)
    .sort()
    .reverse()[0];
  const indexDay = moment();
  const slotsByDay = {};

  if (!maxDate || indexDay.isAfter(maxDate, 'date')) {
    // No upcoming slots.
    return slotsByDay;
  }

  // Iterate from the current day to the day of the last slot.
  while (indexDay.isSameOrBefore(maxDate, 'date')) {
    const date = indexDay.format('YYYY-MM-DD');

    slotsByDay[date] = slots.filter(slot => slot.date === date);
    slotsByDay[date] = uniqBy(slotsByDay[date], 'time');
    slotsByDay[date] = orderBy(slotsByDay[date], ['time'], ['asc']);

    indexDay.add(1, 'day');
  }

  return slotsByDay;
};

export const checkTimeSlotsWidgetEnabled = (slotsByDay, state, locality) => {
  const slotsCount = Object.values(slotsByDay || {}).flat().length;
  const stateValid =
    CONFIG.MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES === '*' ||
    CONFIG.MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES.some(
      configState => configState.toLowerCase() === state.toLowerCase(),
    );
  const localityValid =
    CONFIG.MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES === '*' ||
    CONFIG.MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES.some(
      configLocality => configLocality.toLowerCase() === locality.toLowerCase(),
    );

  return (
    CONFIG.MATCH_RESULTS_TIME_SLOTS_WIDGET === true &&
    slotsCount > 0 &&
    stateValid &&
    localityValid
  );
};
