import request, { GET } from '../utils/request';

export const apiGetClinics = clinicIds =>
  request(GET, `${CONFIG.API_URL}/clinic/v2/?ids=${clinicIds}`);

export const apiGetServicesForSpecialty = specialtyId =>
  request(GET, `${CONFIG.API_URL}/service?specialty=${specialtyId}`);

export const apiGetFeaturesForSpecialty = specialtyId =>
  request(GET, `${CONFIG.API_URL}/feature?specialty=${specialtyId}`);

export const apiGetClinicPerformanceBadges = clinicId =>
  request(GET, `${CONFIG.API_URL}/clinic/analytics/badges/${clinicId}`);
