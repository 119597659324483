import CONFIG from '../../app.config';

export const initPypestream = () => {
  if (!CONFIG.PYPESTREAM_SCRIPT_SRC) {
    return;
  }

  const insertScript = () => {
    if (document.getElementById('pypestream-script')) return;

    const scriptEl = document.createElement('script');
    scriptEl.id = 'pypestream-script';
    scriptEl.type = 'text/javascript';
    scriptEl.src = `${CONFIG.PYPESTREAM_SCRIPT_SRC}?ts=${Date.now()}`;
    scriptEl.async = true;
    document.body.appendChild(scriptEl);
  };

  if (document.readyState === 'complete') {
    insertScript();
  } else {
    window.addEventListener('load', insertScript);
  }
};

export const setupPypestream = user => {
  if (!CONFIG.PYPESTREAM_APP_ID) {
    return;
  }

  const passthrough = [];

  if (user && user.pypestreamAuthToken) {
    passthrough.push(
      {
        attribute: 'user_id',
        label: 'user_id',
        value: user.email,
      },
      {
        attribute: 'user_token',
        label: 'user_token',
        value: user.pypestreamAuthToken,
      },
    );
  }

  window.pypestreamConfig = {
    APP_ID: CONFIG.PYPESTREAM_APP_ID,
    language: 'en',
    passthrough: JSON.stringify(passthrough),
  };
  window.pypestreamBooted = false;
};

export const configurePypestream = () => {
  const containerEl = document.getElementById('pypestream-container');
  window.Pypestream.config(window.pypestreamConfig);

  window.Pypestream.onShow(() => {
    containerEl.style.zIndex = 99999;
  });

  window.Pypestream.onHide(() => {
    containerEl.style.zIndex = -1;
  });
};
