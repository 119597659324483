// 3rd Party
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';

// Other
import { BREAKPOINTS } from 'common/styles/style-constants';

// Code

export const DIMENSIONS = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  WIDESCREEN: 'WIDESCREEN',
};

const WindowDimensionsCtx = createContext({
  layout: 'DESKTOP',
  width: 1600,
  height: 800,
});

const WindowDimensionsProvider = ({ children }) => {
  const widthToLayout = width => {
    if (width >= BREAKPOINTS.widescreenMin) {
      return DIMENSIONS.WIDESCREEN;
    }
    if (width >= BREAKPOINTS.desktopMin) {
      return DIMENSIONS.DESKTOP;
    }
    if (width > BREAKPOINTS.mobileMax) {
      return DIMENSIONS.TABLET;
    }
    return DIMENSIONS.MOBILE;
  };

  const [dimensions, setDimensions] = useState({
    layout: widthToLayout(window.innerWidth),
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        layout: widthToLayout(window.innerWidth),
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  );
};

WindowDimensionsProvider.propTypes = {
  children: PropTypes.object,
};

export default WindowDimensionsProvider;
export const useWindowDimensions = () => useContext(WindowDimensionsCtx);
