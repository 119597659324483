export class StateHandlerRegistry {
  constructor() {
    this._stateHandlers = [];
  }

  register(stateHandler) {
    this._stateHandlers.push(stateHandler);
  }

  getStateHandler(actionName) {
    return this._stateHandlers.find(
      stateHandler => stateHandler.actionName === actionName,
    );
  }
}

StateHandlerRegistry.singleton = new StateHandlerRegistry();
