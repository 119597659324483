import { Signup } from './signup';
import { RequestOffer } from '../offer/request-offer';
import { sendToLogger } from 'connect/saga/helpers';
import { call, select } from 'redux-saga/effects';
import { selectDataUser } from 'connect/selectors';
import { SagaHandler } from '../SagaHandler';

class _SignupWithOffer extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/SIGNUP_WITH_OFFER',
    });
  }

  createAction({ userData, match, matchToken, offerCode }) {
    return {
      type: this._actionName,
      userData,
      match,
      matchToken,
      offerCode,
    };
  }

  *saga({ userData, match, matchToken, offerCode }) {
    try {
      const user = yield select(selectDataUser);
      const isUserAuthenticated = Boolean(user?.authenticated);

      // Skip signup if user is already authenticated
      if (!isUserAuthenticated) {
        yield call(Signup.saga, { userData, match, matchToken });
        const signupError = yield select(Signup.error.selector);
        if (signupError) {
          return;
        }
      }

      yield call(RequestOffer.saga, { payload: offerCode });
    } catch (error) {
      sendToLogger(error, 'signupUserAndApplyOffer');
    }
  }
}

export const SignupWithOffer = new _SignupWithOffer();
