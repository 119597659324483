import { sendToLogger } from 'connect/saga/helpers';
import { call, put } from 'redux-saga/effects';
import { SagaHandler } from '../SagaHandler';
import { updatePatient } from 'connect/actions';
import { apiUpdatePatient } from 'endpoints/patient-endpoints';
import { UpsertIterableUser } from '../upsert-iterable-user/upsert-iterable-user';

/*
  Updates patient in the API and Redux silently (no errors, loading, or success messages)
  Useful for non-critical updates where we don't want errors to block the user
*/
class _UpdatePatientSilently extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/UPDATE_PATIENT_SILENTLY',
    });
  }

  *saga({ payload }) {
    const { patientId, startedCheckoutAt, lastSeenAt } = payload;
    try {
      // Expand this as needed
      const body = {
        ...(startedCheckoutAt && { startedCheckoutAt }),
        ...(lastSeenAt && { lastSeenAt }),
      };

      if (Object.keys(body).length === 0) {
        return;
      }

      // Update patient in API
      const patient = yield call(apiUpdatePatient, patientId, body);
      // Update patient in Redux
      yield put(updatePatient(patient));

      if (startedCheckoutAt || lastSeenAt) {
        // Update user in Iterable - this will update the startedCheckoutAt and lastSeenAt fields there
        yield call(UpsertIterableUser.saga, {
          payload: { allowEmptyDataFields: true },
        });
      }
    } catch (error) {
      sendToLogger(error, 'updatePatientSilently');
    }
  }
}

export const UpdatePatientSilently = new _UpdatePatientSilently();
