import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-OC-App': 'search-frontend',
  },
  withCredentials: true,
});

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';

const request = async (method, endpoint, body = {}, params = {}) => {
  const response = await axiosInstance[method](endpoint, body, params);
  return response.data;
};

export const upload = async (method, endpoint, formData, params = {}) => {
  const response = await axiosInstance[method](
    endpoint,
    formData,
    Object.assign(
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-OC-App': 'search-frontend',
        },
      },
      params,
    ),
  );
  return response.data;
};

export default request;
