// colors organized from lightest to darkest by group
export const colors = {
  white: '#FFFFFF',
  black: '#000000',

  mutedGolden: '#f2c164',

  // greys
  offWhite: '#FDFDFD',
  snow: '#f8f8f5',
  nickel: '#F3F1F3',
  silver: '#D0CDD0',
  ash: '#9e9a9f',
  slate: '#6F6A71', // Text secondary
  charcoal: '#4b4b4b',
  darkCharcoal: '#272525',
  gray: '#F0F2F5',
  gray4: '#E8E8E8',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#262626',
  gray10: '#000000',
  gray11: '#C9C9C9',
  gray12: '#D8D8D8',
  gray13: '#B0B0B0',
  gray14: '#F0F0F0',
  gray15: '#AAAAAA',

  // blues
  superPracticeBlue: '#5A99F2', // Only for the updated super-practice logo
  lightBlue: '#D1D8DF',
  blue: '#537087',
  darkBlue: '#254056',
  navy: '#527086',
  lightSage: '#e2f1ee',
  sage: '#9ABEB5',
  sageDark: '#507C75',
  sage5: '#25A186',
  sage6: '#257D69',
  sage7: '#16574A',
  sage8: '#0A302A',
  sage9: '#020A09',
  mint: '#EFF5F4',
  mintDark: '#9ABFB6',

  // oranges/red
  yellow: '#FBBC65',
  coralLight: '#FABDB4',
  coral: '#F37F6E',
  coralDark: '#c25447',
  coral5: '#D47461',
  coral6: '#C74D3B',
  coral7: '#B55B4C',
  orange: '#F37F6E',
  danger: '#c25447',

  // greens
  lightGreen: '#AEdA5F',
  green: '#507C75',
  forestGreen: '#3AC18D',
  success: '#9ABEB5',

  // social
  facebook: '#007ad9',
  google: '#de5246',

  transparent: 'transparent',
};

export const BREAKPOINTS = {
  widescreenMin: 1200,
  desktopMin: 992,
  smallTabletMin: 600,
  tabletMin: 768,
  tabletMax: 991,
  mobileMax: 767,
  mobileSmall: 400,
};

export const CONTENT_BREAKPOINTS = {
  widescreen: 1170,
  desktop: 970,
  tablet: 750,
};

export const DARKEN_AMOUNT = 0.1;

export const ratingColors = {
  0: colors.sageDark,
  1: colors.sageDark,
  2: colors.sageDark,
  3: colors.sageDark,
  4: colors.sageDark,
  5: colors.sageDark,
};

export const NAV_HEIGHT = 62;

export const FONT_FAMILIES = {
  circular: "'Circular', Helvetica, Arial, sans-serif",
  openSans: "'Open Sans', Helvetica, Arial, sans-serif",
  SFProDisplay:
    "'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
};
