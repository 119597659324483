export const GtmDataLayerEvent = Object.freeze({
  SEARCH_QUESTION_WELCOME_REACHED: 'questionWelcomeReached',
  SEARCH_QUESTION_ATTITUDE_REACHED: 'questionAttitudeReached',
  SEARCH_QUESTION_INSURANCE_REACHED: 'questionInsuranceReached',
  SEARCH_RESULTS_REACHED: 'matchResultsReached',
  APPOINTMENT_CHECKOUT_ACCOUNT_CREATED: 'appointmentCheckoutAccountCreated',
  APPOINTMENT_CHECKOUT_ACCOUNT_VERIFIED: 'appointmentCheckoutAccountVerified',
  APPOINTMENT_REQUESTED: 'appointmentRequested',
});

export const trackGtmDataLayerEvent = eventName => {
  if (eventName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
    });
  }
};
