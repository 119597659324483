import request, { GET } from 'utils/request';
import { SagaHandler } from '../SagaHandler';
import { call, put } from 'redux-saga/effects';
import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';

const apiGetMarketName = ({
  country,
  region,
  locality,
  county,
  postalCode,
}) => {
  const params = new URLSearchParams({
    country: country || '',
    region: region || '',
    locality: locality || '',
    county: county || '',
    postalCode: postalCode || '',
  });
  return request(GET, `${CONFIG.API_URL}/market?${params.toString()}`);
};

class _UserMarketName extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/USER_MARKET',
      stateKey: 'userMarketName',
    });
  }

  createActionFromAddress(address) {
    return this.createAction({
      country: address.country,
      region: address.administrative_area_level_1,
      locality: address.locality,
      postalCode: address.postal_code,
      county: address.administrative_area_level_2,
    });
  }

  *saga({ payload: { country, region, locality, county, postalCode } }) {
    const valueHandler = this.value;
    const loadingHandler = this.loading;
    const errorHandler = this.error;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));

      const response = yield call(apiGetMarketName, {
        country,
        region,
        locality,
        county,
        postalCode,
      });
      yield put(valueHandler.update(response.market));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      yield put(errorHandler.update(errorMessage));
      sendToLogger(error, 'UserMarketName');
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const UserMarketName = new _UserMarketName();
