import { AppendAnonymousSessionData } from './anonymous-session/append-anonymous-session-data';
import { GetAnonymousSessionData } from './anonymous-session/get-anonymous-session-data';
import { UpdateAnonymousSessionData } from './anonymous-session/update-anonymous-session-data';
import { RequestOffer } from './offer/request-offer';
import { Signup } from './user/signup';
import { SignupWithOffer } from './user/signup-with-offer';
import { UserMarketName } from './user/user-market-name';
import { RequestReferralPromotion } from './offer/request-referral-promotion';
import { UpdatePatientSilently } from './update-patient-silently/update-patient-silently';
import { UpsertIterableUser } from './upsert-iterable-user/upsert-iterable-user';
import { GetExperimentVariants } from './experiment/get-experiment-variants';
import Logger from 'utils/Logger';

export class SagaHandlerRegistry {
  constructor(handlers) {
    this._handlers = handlers || [
      // Signup
      Signup,
      SignupWithOffer,

      // User info
      UserMarketName,

      // Offers
      RequestOffer,
      RequestReferralPromotion,

      // Anonymous session
      GetAnonymousSessionData,
      UpdateAnonymousSessionData,
      AppendAnonymousSessionData,

      // Patient
      UpdatePatientSilently,

      // API Endpoints
      UpsertIterableUser,

      // Experiments
      GetExperimentVariants,
    ];

    // Inject handlers with dependencies
    // This allows handlers to access other handlers without circular dependencies
    //
    // Injecting them when the registry is created instead of when the handler runs
    // allows us to throw an error when the app starts instead of when the handler runs
    this._handlers.forEach(handler => {
      handler.injectDependencies(this);
    });
  }

  getHandlers() {
    return this._handlers;
  }

  getHandler(actionName) {
    const handler = this.getHandlers().find(h => {
      const name = h.actionName.replace('data/', '');
      return name === actionName;
    });

    if (!handler) {
      const error = new Error(
        `Could not find handler with action name: ${actionName}`,
      );
      Logger.error('SagaHandlerRegistry', error);
      throw error;
    }

    return handler;
  }
}
