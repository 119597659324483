import { StateHandlerRegistry } from './registry';

/**
 * A base class for handling state changes in Redux.
 * Create actions to dispatch actions using the `update` method.
 *
 * Note: State handles are automatically registered on creation.
 */
export class ReduxStateHandler {
  constructor(actionName, statePath, legacyOptions = {}) {
    this._actionName = actionName;
    this._statePath = statePath.split('.');
    this._legacyOptions = legacyOptions;

    this.selector = this.selector.bind(this);
    this.handleAction = this.handleAction.bind(this);

    StateHandlerRegistry.singleton.register(this);
  }

  get actionName() {
    return this._actionName;
  }

  /**
   * @example dispatch(userStateHandler.update({ name: 'John' }));
   * @param {*} value - The value to dispatch
   */
  update(value) {
    return {
      type: this._actionName,
      payload: value,
    };
  }

  selector(state) {
    const { legacyKey } = this._legacyOptions;

    const result = this._statePath.reduce((acc, key) => acc?.[key], state.data);

    if (legacyKey && result === undefined) {
      return state.data[legacyKey];
    }

    return result;
  }

  handleAction(state, action) {
    const stateToUpdate = this._statePath.slice(0, -1).reduce((acc, key) => {
      acc[key] = acc[key] || {};
      return acc[key];
    }, state);
    const lastStateKey = this._statePath[this._statePath.length - 1];

    // eslint-disable-next-line no-param-reassign
    stateToUpdate[lastStateKey] = action.payload;
  }
}
