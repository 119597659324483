import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';
import { call, put } from 'redux-saga/effects';
import { SagaHandler } from '../SagaHandler';
import request, { GET } from 'utils/request';
import { EMPTY_OFFER } from 'connect/constants';

const DEFAULT_PROMOTION = {
  displayName: '$50 Prepaid VISA Card',
  giftCardType: 'VISA',
  referrerValue: 50,
  referralValue: 50,
};

const apiGetReferralPromotion = referralCode =>
  request(GET, `${CONFIG.API_URL}/referralPromotion/eligible/${referralCode}`);

class _RequestReferralPromotion extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/REQUEST_REFFERAL_PROMOTION',
      stateKey: 'requestReferralPromotion',
      legacyOptions: {
        valueStateKey: 'promotion',
      },
      dependencies: ['REQUEST_OFFER'],
    });
  }

  *saga({ payload }) {
    const referralCode = payload;
    const errorHandler = this.error;
    const loadingHandler = this.loading;
    const valueHandler = this.value;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));

      const promotion = yield call(apiGetReferralPromotion, referralCode);

      yield put(valueHandler.update(promotion || DEFAULT_PROMOTION));

      const requestOffer = this._dependencies.REQUEST_OFFER;
      yield put(requestOffer.value.update(EMPTY_OFFER));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      sendToLogger(error, 'requestOffer');
      yield put(errorHandler.update(errorMessage));
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const RequestReferralPromotion = new _RequestReferralPromotion();
