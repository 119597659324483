// Other
import blankCoralInstantImg from '../../../assets/images/maps/pin-blank-coral-instant.png';
import blankWhiteCoralInstantImg from '../../../assets/images/maps/pin-blank-white-coral-instant.png';
import dotCoralImg from '../../../assets/images/maps/pin-coral-dotted.png';
import blankCoralImg from '../../../assets/images/maps/pin-blank-coral.png';
import blankWhiteCoral from '../../../assets/images/maps/pin-blank-white-coral.png';
import blankBlueInstantImg from '../../../assets/images/maps/pin-blank-blue-instant.png';
import blankWhiteBlueInstantImg from '../../../assets/images/maps/pin-blank-white-blue-instant.png';
import dotBlueImg from '../../../assets/images/maps/pin-blue-dotted.png';
import blankBlueImg from '../../../assets/images/maps/pin-blank-blue.png';
import blankWhiteBlue from '../../../assets/images/maps/pin-blank-white-blue.png';
import locationPinImg from '../../../assets/images/maps/location-pin.png';
import CONFIG from '../../../../app.config';

// Exports
export const GOOGLE_SCRIPT_ID = 'googlePlaces';
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${
  CONFIG.GOOGLE_MAPS_API_KEY
}&v=3.exp&libraries=geometry,drawing,places`;

export const INFO_WINDOW_OPTIONS = {
  disableAutoPan: true,
};

export const MAP_OPTIONS = {
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  streetViewControl: false,
};

export const MARKER_ICONS = {
  // userLocation: {
  //   url: userLocationImg,
  //   scaledSize: { height: 26, width: 26 },
  //   anchor: { x: 13, y: 13 },
  // },
  // clinicPin: {
  //   url: '/images/discovery/maps/pin-clinic.png',
  //   scaledSize: new google.maps.Size(40, 47),
  //   anchor: new google.maps.Point(20, 40)
  // },
  // dentistPin: {
  //   url: '/images/discovery/maps/pin-dentist.png',
  //   scaledSize: new google.maps.Size(40, 47),
  //   anchor: new google.maps.Point(20, 40)
  // },
  // blankTeal: {
  //   url: '/images/discovery/maps/pin-blank-teal.png',
  //   scaledSize: new google.maps.Size(46, 55),
  //   anchor: new google.maps.Point(23, 45),
  //   labelOrigin: new google.maps.Point(21, 23)
  // },
  // blankWhite: {
  //   url: '/images/discovery/maps/pin-blank-white.png',
  //   scaledSize: new google.maps.Size(46, 55),
  //   anchor: new google.maps.Point(23, 45),
  //   labelOrigin: new google.maps.Point(21, 23)
  // },
  locationPin: {
    url: locationPinImg,
    scaledSize: { height: 40, width: 32 },
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
  },
  blankCoral: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: blankCoralImg,
  },
  blankWhiteCoral: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: blankWhiteCoral,
  },
  coralDotted: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: dotCoralImg,
  },
  blankCoralInstant: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 12 },
    scaledSize: { height: 40, width: 30 },
    url: blankCoralInstantImg,
  },
  blankWhiteCoralInstant: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 12 },
    scaledSize: { height: 40, width: 30 },
    url: blankWhiteCoralInstantImg,
  },
  blankBlue: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: blankBlueImg,
  },
  blankWhiteBlue: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: blankWhiteBlue,
  },
  blueDotted: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 16 },
    scaledSize: { height: 40, width: 30 },
    url: dotBlueImg,
  },
  blankBlueInstant: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 12 },
    scaledSize: { height: 40, width: 30 },
    url: blankBlueInstantImg,
  },
  blankWhiteBlueInstant: {
    anchor: { x: 15, y: 40 },
    labelOrigin: { x: 15, y: 12 },
    scaledSize: { height: 40, width: 30 },
    url: blankWhiteBlueInstantImg,
  },
};
