export const formatDateOfBirth = (year, month, day) => {
  const formattedMonth = month < 10 ? `0${month}`.slice(-2) : month;
  const formattedDay = day < 10 ? `0${day}`.slice(-2) : day;
  return `${year}-${formattedMonth}-${formattedDay}`;
};

export const keepAlphaNumeric = memberID => memberID.replace(/[^0-9a-z]/gi, '');

export const memberIdFormValidation = values => ({
  memberId: {
    value: values.member,
    errors: [new Error('')],
  },
});

export const makeInsuranceState = (providerInfo, planInfo) => ({
  plan: planInfo ? planInfo.name : undefined,
  planId: planInfo ? planInfo.id : undefined,
  provider: providerInfo.name,
  providerId: providerInfo.id,
});

export function checkMemberIdFormat(memberId) {
  const result = {
    valid: true,
    description: null,
  };

  const value = (memberId || '').trim();

  if (value === '') {
    result.valid = false;
    result.description = 'Please enter a member ID.';
  }

  // TODO: checking the format of the member ID currently is difficult
  //  because insurance companies have very different formats and there is no any validation API.
  //  It's worth passing even bad formatted values rather than block appointment requests.

  return result;
}
