import request, { GET } from 'utils/request';
import { SagaHandler } from '../SagaHandler';
import { call, put } from 'redux-saga/effects';
import { extractErrorMessage, sendToLogger } from 'connect/saga/helpers';

const apiGetExperimentVariants = () =>
  request(GET, `${CONFIG.API_URL}/experiments`);

class _GetExperimentVariants extends SagaHandler {
  constructor() {
    super({
      actionName: 'api/GET_EXPERIMENT_VARIANTS',
      stateKey: 'experimentVariants',
    });
  }

  *saga() {
    const errorHandler = this.error;
    const loadingHandler = this.loading;
    const valueHandler = this.value;

    try {
      yield put(loadingHandler.update(true));
      yield put(errorHandler.update(null));
      const experimentVariants = yield call(apiGetExperimentVariants);
      yield put(valueHandler.update(experimentVariants));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);

      sendToLogger(error, 'GetExperimentVariants');
      yield put(errorHandler.update(errorMessage));
      yield put(valueHandler.update(null));
    } finally {
      yield put(loadingHandler.update(false));
    }
  }
}

export const GetExperimentVariants = new _GetExperimentVariants();
