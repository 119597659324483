import request, { GET } from '../utils/request';
import { SPECIALTY } from 'common/constants';

export const apiGetInsuranceList = region =>
  request(
    GET,
    `${CONFIG.API_URL}/insuranceprovider?region=${region}&specialtyIds=${
      SPECIALTY.dentist
    }`,
  );

export const apiGetInsuranceListForCanada = () =>
  request(GET, `${CONFIG.API_URL}/v2/insuranceprovider?country=CA`);
