import { SagaHandler } from '../SagaHandler';
import { call, select } from 'redux-saga/effects';
import { GetAnonymousSessionData } from './get-anonymous-session-data';
import { UpdateAnonymousSessionData } from './update-anonymous-session-data';

class _AppendAnonymousSessionData extends SagaHandler {
  constructor() {
    super({
      actionName: 'api/APPEND_ANONYMOUS_SESSION_DATA',
    });
  }

  *saga({ payload: { key, value } }) {
    const existingStorage = yield select(
      GetAnonymousSessionData.value.selector,
    );
    const existingValue = existingStorage[key] || [];

    // Add new value and remove duplicates
    const newValue = [...existingValue, value].filter(
      (val, index, array) => array.indexOf(val) === index,
    );

    yield call(
      UpdateAnonymousSessionData.saga,
      UpdateAnonymousSessionData.createAction({
        [key]: newValue,
      }),
    );
  }
}

export const AppendAnonymousSessionData = new _AppendAnonymousSessionData();
