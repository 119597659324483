/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

// Types
export const CLEAR_CLINIC = 'data/CLEAR_CLINIC';
export const CREATE_PATIENT = 'data/CREATE_PATIENT';
export const LOGIN_USER = 'data/LOGIN_USER';
export const LOGIN_USER_WITH_TOKEN = 'data/LOGIN_USER_WITH_TOKEN';
export const LOGOUT_USER = 'data/LOGOUT_USER';
export const REQUEST_APPOINTMENT = 'data/REQUEST_APPOINTMENT';
export const REQUEST_CLINIC = 'data/REQUEST_CLINIC';
export const REQUEST_CLINIC_PERFORMANCE_BADGES =
  'data/REQUEST_CLINIC_PERFORMANCE_BADGES';
export const REQUEST_FEATURES_FOR_SPECIALTY =
  'data/REQUEST_FEATURES_FOR_SPECIALTY';
export const CREATE_MATCH_AND_RESULTS = 'data/CREATE_MATCH_AND_RESULTS';
export const RECREATE_MATCH_AND_RESULTS = 'data/RECREATE_MATCH_AND_RESULTS';
export const REQUEST_MATCH_AND_RESULTS = 'data/REQUEST_MATCH_AND_RESULTS';
export const REQUEST_PATIENT = 'data/REQUEST_PATIENT';
export const REQUEST_SERVICES_FOR_SPECIALTY =
  'data/REQUEST_SERVICES_FOR_SPECIALTY';
export const REQUEST_EMAIL_VERIFICATION = 'data/REQUEST_EMAIL_VERIFICATION';
export const REQUEST_PHONE_VERIFICATION = 'data/REQUEST_PHONE_VERIFICATION';
export const REQUEST_PHONE_VERIFICATION_SMS_STATUS =
  'data/REQUEST_PHONE_VERIFICATION_SMS_STATUS';
export const REQUEST_USER_AND_PATIENT = 'data/REQUEST_USER_AND_PATIENT';
export const RESET_CHECKOUT_FORM_DATA = 'data/RESET_CHECKOUT_FORM_DATA';
export const RESET_MATCH_INSURANCE = 'data/RESET_MATCH_INSURANCE';
export const RESET_MATCHES = 'data/RESET_MATCHES';
export const SAVE_MATCHES = 'data/SAVE_MATCHES';
export const UPDATE_APPOINTMENT_REQUESTED = 'data/UPDATE_APPOINTMENT_REQUESTED';
export const UPDATE_CHECKOUT_FORM_DATA = 'data/UPDATE_CHECKOUT_FORM_DATA';
export const UPDATE_CLINIC = 'data/UPDATE_CLINIC';
export const UPDATE_CLINIC_PERFORMANCE_BADGES =
  'data/UPDATE_CLINIC_PERFORMANCE_BADGES';
export const UPDATE_DM_PROPS = 'data/UPDATE_DM_PROPS';
export const UPDATE_BOOKING_WIDGET_SLOT = 'data/UPDATE_BOOKING_WIDGET_SLOT';
export const UPDATE_EXCLUDE_PROVIDER_ID = 'data/UPDATE_EXCLUDE_PROVIDER_ID';
export const UPDATE_EXISTING_USER_EMAIL = 'data/UPDATE_EXISTING_USER_EMAIL';
export const UPDATE_FEATURES_FOR_SPECIALTY =
  'data/UPDATE_FEATURES_FOR_SPECIALTY';
export const UPDATE_INSURANCE_PROVIDER_AND_PLAN =
  'data/UPDATE_INSURANCE_PROVIDER_AND_PLAN';
export const UPDATE_INSURANCE_VERIFICATION_RESPONSE =
  'data/UPDATE_INSURANCE_ONEDERFUL_RESPONSE';
export const UPDATE_CREATING_PATIENT = 'data/UPDATE_CREATING_PATIENT';
export const UPDATE_LOADING_EMAIL_VERIFICATION =
  'data/UPDATE_LOADING_EMAIL_VERIFICATION';
export const UPDATE_LOADING_PHONE_VERIFICATION =
  'data/UPDATE_LOADING_PHONE_VERIFICATION';
export const UPDATE_LOADING_PHONE_VERIFICATION_REQUEST =
  'data/UPDATE_LOADING_PHONE_VERIFICATION_REQUEST';
export const UPDATE_LOADING_USER = 'data/UPDATE_LOADING_USER';
export const UPDATE_LOADING_LOGIN = 'data/UPDATE_LOADING_LOGIN';
export const UPDATE_LOADING_MATCH = 'data/UPDATE_LOADING_MATCH';
export const UPDATE_MATCH = 'data/UPDATE_MATCH';
export const UPDATE_MATCH_AND_RESULTS = 'data/UPDATE_MATCH_AND_RESULTS';
export const UPDATE_ONGOING_CARE_NO_MATCH = 'data/UPDATE_ONGOING_CARE_NO_MATCH';
export const UPDATE_REASON_NO_MATCH = 'data/UPDATE_REASON_NO_MATCH';
export const UPDATE_MEMBER_ID = 'data/UPDATE_MEMBER_ID';
export const UPDATE_PATIENT = 'data/UPDATE_PATIENT';
export const UPDATE_PATIENT_DETAILS = 'data/UPDATE_PATIENT_DETAILS';
export const UPDATE_PHONE_VERIFICATION_SMS_STATUS =
  'data/UPDATE_PHONE_VERIFICATION_SMS_STATUS';
export const UPDATE_SELECTED_APPOINTMENT = 'data/UPDATE_SELECTED_APPOINTMENT';
export const UPDATE_SELECTED_RESULT = 'data/UPDATE_SELECTED_RESULT';
export const UPDATE_SERVICES_FOR_SPECIALTY =
  'data/UPDATE_SERVICES_FOR_SPECIALTY';
export const UPDATE_SPECIFIC_AVAILABILITIES =
  'data/UPDATE_SPECIFIC_AVAILABILITIES';
export const UPDATE_DIRECT_TO_PROFILE_LINK =
  'data/UPDATE_DIRECT_TO_PROFILE_LINK';
export const UPDATE_USER_AND_PATIENT = 'data/UPDATE_USER_AND_PATIENT';
export const UPDATE_CHECKOUT_ERROR_STATUS = 'data/UPDATE_ERROR_CHECKOUT_STATUS';
export const UPDATE_LOGIN_ERROR = 'data/UPDATE_LOGIN_ERROR';
export const UPDATE_VALIDATE_PROMO_CODE_ERROR =
  'data/UPDATE_VALIDATE_PROMO_CODE_ERROR';
export const UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR =
  'data/UPDATE_REQUEST_PHONE_VERIFICATION_SMS_STATUS_ERROR';
export const UPDATE_CJ_TAGS = 'data/UPDATE_CJ_TAGS';
export const VALIDATE_PROMO_CODE = 'data/VALIDATE_PROMO_CODE';
export const VERIFY_EMAIL = 'data/VERIFY_EMAIL';
export const VERIFY_PHONE = 'data/VERIFY_PHONE';
export const POST_FEEDBACK = 'data/POST_FEEDBACK';
export const UPDATE_LOADING_ELIGIBLE_PARTNERS =
  'data/UPDATE_LOADING_ELIGIBLE_PARTNERS';
export const UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS =
  'data/UPDATE_LOADING_PHONE_VERIFICATION_SMS_STATUS';
export const GET_ELIGIBLE_PARTNERS = 'data/GET_ELIGIBLE_PARTNERS';
export const UPDATE_ELIGIBLE_PARTNERS = 'data/UPDATE_ELIGIBLE_PARTNERS';
export const POST_PARTNER_LEAD = 'data/POST_PARTNER_LEAD';
export const GET_INSURANCE_LIST = 'data/GET_INSURANCE_LIST';
export const UPDATE_INSURANCE_LIST = 'data/UPDATE_INSURANCE_LIST';
export const UPDATE_INSURANCE_LIST_REGION = 'data/UPDATE_INSURANCE_LIST_REGION';
export const GET_INSURANCE_LIST_FOR_CANADA =
  'data/GET_INSURANCE_LIST_FOR_CANADA';
export const UPDATE_INSURANCE_LIST_FOR_CANADA =
  'data/UPDATE_INSURANCE_LIST_FOR_CANADA';
export const UPDATE_PYPESTREAM_READY = 'data/UPDATE_PYPESTREAM_READY';
export const EMAIL_MATCH_CLINIC = 'data/EMAIL_MATCH_CLINIC';
export const EMAIL_MATCH_CLINIC_RESULT = 'data/EMAIL_MATCH_CLINIC_RESULT';

// Other
export const DENTIST_REASONS_NAMES = {
  checkup: 'Checkup & Cleaning',
  services: 'Specific Treatment',
  urgent: 'Urgent Issue',
};

export const EMPTY_APPOINTMENT = {
  createdAt: '',
  date: null,
  duration: null,
  history: [],
  id: null,
  insuranceData: {},
  match: {},
  metadata: {},
  offer: null,
  offerClaimData: {},
  offerType: {},
  offeredAppointmentType: {},
  patient: {},
  prime: false,
  primeInvoice: null,
  provider: {},
  requestBlocks: null,
  requestData: {},
  rescheduleSlotOptions: null,
  reservedAppointmentSlot: {},
  serviceData: null,
  startTime: null,
  status: '',
  updatedAt: '',
  viewedByClinicAt: null,
  visited: null,
};

export const EMPTY_CLINIC = {
  address: {},
  averageRating: 0,
  description: '',
  features: [],
  hours: [],
  id: 0,
  insurancePlans: [],
  metadata: {},
  name: '',
  photos: [],
  primaryPhoto: {},
  providerIds: [],
  providers: [],
  reviews: [],
  reviewsCount: 0, // sum of all doctor reviews, not the count of all clinic reviews
  slots: [],
  covidSafetyProceduresMap: {},
};

export const EMPTY_INSURANCE = {
  provider: null,
  socialAssistance: false,
  topProvider: false,
};

export const EMPTY_MATCH = {
  chosenProvider: null,
  createdAt: null,
  id: null,
  latitude: null,
  location: {
    address: { country: null },
    formattedAddress: null,
    latitude: null,
    longitude: null,
  },
  longitude: null,
  other: {
    alternate: false,
    attitude: null,
    availability: null,
    criteria: [],
    dentalAnxiety: null,
    distanceWeight: 0,
    dmProps: { promo_code: '' },
    doctorGender: null,
    goals: [],
    hasInsurance: null,
    howSoon: null,
    insurance: EMPTY_INSURANCE,
    isClinicUser: false,
    isPatient: false,
    lastVisited: null,
    matchConfig: [],
    mpUserDistinctId: null,
    newAlgorithm: false,
    primeProvidersOnly: false,
    reason: null,
    reasonRaw: {},
    specificAvailabilities: [],
    subReasonIds: null,
    suspectedClinicUser: false,
    trafficSource: {
      channel: '',
      subChannel: '',
      subChannelDetailed: '',
    },
    trafficSourceLastTouch: {
      channel: '',
      subChannel: '',
      subChannelDetailed: '',
    },
    unbounceProps: {},
    userId: null,
    utmTags: {
      campaign: '',
      content: '',
      medium: '',
      source: '',
      term: '',
    },
  },
  specialty: { createdAt: null, id: null, updatedAt: null },
  updatedAt: null,
};

export const EMPTY_OFFER = {
  active: false,
  cost: null,
  createdAt: null,
  deletedAt: null,
  id: null,
  images: { horizontal: null, horizontalLight: null, square: null },
  metadata: { tangoCardId: null },
  name: null,
  promoted: null,
  slug: null,
  type: null,
  updatedAt: null,
};

export const EMPTY_RESULT = {
  averageRating: 0,
  bookedByPastMonth: 0,
  id: 0,
  locality: '',
  name: '',
  reviewsCount: 0,
};

export const CHECKOUT_ERROR_TYPES = {
  SLOT_TAKEN: 'slot_taken',
  APPOINTMENT_REQUESTED_ALREADY: 'appointment_requested_already',
  NOT_PATIENT: 'not_patient',
  GENERIC: 'generic',
  LOGGED_IN_AS_NOT_PATIENT: 'logged_in_as_not_patient',
  EMAIL_INVALID: 'email_invalid',
  PHONE_INVALID: 'phone_invalid',
  EMAIL_ALREADY_REGISTERED: 'email_already_registered',
  PROVIDER_NO_ONLINE_BOOKING: 'provider_no_online-booking',
  PROVIDER_NO_RESERVED_SLOTS: 'provider_no_reserved_slots',
  SSO_DENIED_ACCESS: 'sso_denied_access',
  SSO_GENERIC: 'sso_generic',
};

export const USER_SIGNUP_ERROR_TYPES = {
  LOGGED_IN_AS_NOT_PATIENT: CHECKOUT_ERROR_TYPES.LOGGED_IN_AS_NOT_PATIENT,
  EMAIL_INVALID: CHECKOUT_ERROR_TYPES.EMAIL_INVALID,
  PHONE_INVALID: CHECKOUT_ERROR_TYPES.PHONE_INVALID,
  EMAIL_ALREADY_REGISTERED: CHECKOUT_ERROR_TYPES.EMAIL_ALREADY_REGISTERED,
};

export const getUserSignupErrorType = errorMessage => {
  if (errorMessage?.startsWith('The email address is not valid.')) {
    return USER_SIGNUP_ERROR_TYPES.EMAIL_INVALID;
  }

  if (
    errorMessage === 'This email has already been registered, please sign in.'
  ) {
    return USER_SIGNUP_ERROR_TYPES.EMAIL_ALREADY_REGISTERED;
  }

  if (errorMessage?.startsWith("The 'mobilePhone' parameter is not valid")) {
    return USER_SIGNUP_ERROR_TYPES.PHONE_INVALID;
  }

  if (errorMessage === 'You must log out first to perform this action.') {
    return USER_SIGNUP_ERROR_TYPES.LOGGED_IN_AS_NOT_PATIENT;
  }

  return null;
};

export const NUM_MATCH_RESULTS_TO_DISPLAY = 9;
