export const COUNTRY = {
  unitedStates: 'United States',
  canada: 'Canada',
};

export const COUNTRY_LOWER_CASE = {
  unitedStates: 'united states',
  canada: 'canada',
};

export const SPECIALTY = {
  dentist: 525,
};

export const topCanadianInsurance = [
  'Sun Life Financial',
  'Manulife Financial',
  'Canada Life',
  'Green Shield Canada',
  'Desjardins Group',
  'Blue Cross',
  'ClaimSecure',
];

export const otherInsurance = {
  id: 'notListed',
  name: "My provider isn't listed",
};

export const MembershipPlan = Object.freeze({
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
});

export const MembershipStatus = Object.freeze({
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
});

export const SPECIAL_CONFIG_IDS = {
  RESET: 'reset-funnel',
  PREFUNNEL: 'prefunnel',
};

// For Redux User Source
export const UTM_SOURCES = Object.freeze({
  MEMBER_ID_REMINDER: 'member-id-reminder',
});
