// Default configuration (development, test environments).
let config = {
  ENV: 'local',
  API_URL: 'https://localhost:1337',
  SEARCH_APP_URL: 'https://localhost:3004',
  SEARCH_APP_V2_URL: 'https://localhost:3015',
  PATIENT_APP_URL: 'https://localhost:3011',
  CLINIC_APP_URL: 'https://localhost:3007',
  CLINIC_APP_V4_URL: 'https://localhost:3014',
  IMAGES_URL: 'https://images-dev.opencare.com/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAhNi-MCo_lmZBLTF8lE64jN8f5Vjfvw3g',
  S3_BUCKET_URL: 'https://images.opencare.com/',
  PYPESTREAM_APP_ID: '36e5c687-1bdd-4a8b-963b-48d77ed1f68b',
  PYPESTREAM_SCRIPT_SRC: 'https://web-sandbox.pypestream.com/api-launcher.js',
  FUNNEL: true,
  MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
  MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
  MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
};

if (process.env.CI === 'true') {
  config = {
    ENV: 'local',
    API_URL: 'https://api-staging.opencare.com',
    SEARCH_APP_URL: 'https://localhost:3004',
    SEARCH_APP_V2_URL: 'https://localhost:3015',
    PATIENT_APP_URL: 'https://localhost:3011',
    CLINIC_APP_URL: 'https://localhost:3007',
    CLINIC_APP_V4_URL: 'https://localhost:3014',
    IMAGES_URL: 'https://images-dev.opencare.com/',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAhNi-MCo_lmZBLTF8lE64jN8f5Vjfvw3g',
    S3_BUCKET_URL: 'https://images.opencare.com/',
    PYPESTREAM_APP_ID: '36e5c687-1bdd-4a8b-963b-48d77ed1f68b',
    PYPESTREAM_SCRIPT_SRC: 'https://web-sandbox.pypestream.com/api-launcher.js',
    FUNNEL: true,
    MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
    MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
    MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
  };
}

// Configuration for ngrok
if (process.env.ENABLE_TUNNEL) {
  config = {
    API_URL: 'https://ngrok-api.local.opencare.com',
    SEARCH_APP_URL: 'https://ngrok.local.opencare.com',
    SEARCH_APP_V2_URL: 'https://ngrok-v2.local.opencare.com',
    PATIENT_APP_URL: 'https://ngrok-patient.local.opencare.com',
    CLINIC_APP_URL: 'https://ngrok-clinic.local.opencare.com',
    CLINIC_APP_V4_URL: 'https://ngrok-clinic-v2.local.opencare.com',
    IMAGES_URL: 'https://images-dev.opencare.com/',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAhNi-MCo_lmZBLTF8lE64jN8f5Vjfvw3g',
    S3_BUCKET_URL: 'https://images.opencare.com/',
    PYPESTREAM_APP_ID: '36e5c687-1bdd-4a8b-963b-48d77ed1f68b',
    PYPESTREAM_SCRIPT_SRC: 'https://web-sandbox.pypestream.com/api-launcher.js',
    FUNNEL: true,
    MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
    MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
    MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
  };
}

if (process.env.NODE_ENV === 'production') {
  if (process.env.ENV_STAGING === 'true') {
    // Staging environment.
    config = {
      ENV: 'staging',
      API_URL: 'https://api-staging.opencare.com',
      SEARCH_APP_URL: 'https://staging.opencare.com',
      SEARCH_APP_V2_URL: 'https://search-staging.opencare.com',
      PATIENT_APP_URL: 'https://patient-staging.opencare.com',
      CLINIC_APP_URL: 'https://app-staging.opencare.com',
      CLINIC_APP_V4_URL: 'https://app-v2-staging.opencare.com',
      IMAGES_URL: 'https://images.opencare.com/',
      GOOGLE_MAPS_API_KEY: 'AIzaSyA6RdTdysqhEwXNGpBe_ugZuuw0arLYuLU',
      S3_BUCKET_URL: 'https://images.opencare.com/',
      PYPESTREAM_APP_ID: '36e5c687-1bdd-4a8b-963b-48d77ed1f68b',
      PYPESTREAM_SCRIPT_SRC:
        'https://web-sandbox.pypestream.com/api-launcher.js',
      FUNNEL: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
      MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
    };
  } else if (process.env.ENV_DEMO === 'true') {
    // demo environment
    config = {
      ENV: 'demo',
      API_URL: 'https://api-demo.opencare.com',
      SEARCH_APP_URL: 'https://demo.opencare.com',
      SEARCH_APP_V2_URL: 'https://search-demo.opencare.com',
      PATIENT_APP_URL: 'https://patient-demo.opencare.com',
      CLINIC_APP_URL: 'https://app-demo.opencare.com',
      CLINIC_APP_V4_URL: 'https://app-v2-demo.opencare.com',
      IMAGES_URL: 'https://images.opencare.com/',
      GOOGLE_MAPS_API_KEY: 'AIzaSyA6RdTdysqhEwXNGpBe_ugZuuw0arLYuLU',
      S3_BUCKET_URL: 'https://images.opencare.com/',
      PYPESTREAM_APP_ID: '36e5c687-1bdd-4a8b-963b-48d77ed1f68b',
      PYPESTREAM_SCRIPT_SRC:
        'https://web-sandbox.pypestream.com/api-launcher.js',
      FUNNEL: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
      MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
    };
  } else {
    // Production environment.
    config = {
      ENV: 'production',
      API_URL: 'https://api.opencare.com',
      SEARCH_APP_URL: 'https://www.opencare.com',
      SEARCH_APP_V2_URL: 'https://search.opencare.com',
      PATIENT_APP_URL: 'https://patient.opencare.com',
      CLINIC_APP_URL: 'https://app.opencare.com',
      CLINIC_APP_V4_URL: 'https://app-v2.opencare.com',
      IMAGES_URL: 'https://images.opencare.com/',
      GOOGLE_MAPS_API_KEY: 'AIzaSyA6RdTdysqhEwXNGpBe_ugZuuw0arLYuLU',
      S3_BUCKET_URL: 'https://images.opencare.com/',
      PYPESTREAM_APP_ID: '16a6ed42-4f66-4121-8d41-968f6ab56020',
      PYPESTREAM_SCRIPT_SRC: 'https://web.pypestream.com/api-launcher.js',
      FUNNEL: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET: true,
      MATCH_RESULTS_TIME_SLOTS_WIDGET_STATES: '*',
      MATCH_RESULTS_TIME_SLOTS_WIDGET_LOCALITIES: '*',
    };
  }
}

module.exports = config;
