import localforage from 'localforage';
import memoryStorageDriver from 'localforage-memoryStorageDriver';

// Setup order of drivers, with a memory fallback for browsers that don't support storage
// (Namely some versions of Safari)
// https://localforage.github.io/localForage/#settings-api-setdriver
// https://github.com/localForage/localForage-memoryStorageDriver
export const StorageSetup = async () => {
  await localforage.defineDriver(memoryStorageDriver).then(() =>
    localforage.setDriver([
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      // eslint-disable-next-line no-underscore-dangle
      memoryStorageDriver._driver,
    ]),
  );
};
