import _ from 'lodash';
import request, { GET, POST, PATCH } from '../utils/request';
import { SPECIALTY } from 'common/constants';

export const apiCreateMatchForPartner = (partnerId, location, other) =>
  request(POST, `${CONFIG.API_URL}/partner/${partnerId}/match`, {
    specialty: SPECIALTY.dentist,
    location,
    other,
  });

export const apiCreatePatientForPartner = (partnerId, patientData) =>
  request(POST, `${CONFIG.API_URL}/partner/${partnerId}/patient`, {
    email: patientData.email,
    emailVerified: true,
    mobilePhone: patientData.mobilePhone,
    phoneVerified: true,
  });

export const apiUpdatePatientForPartner = (partnerId, patientId, patientData) =>
  request(
    PATCH,
    `${CONFIG.API_URL}/partner/${partnerId}/patient/${patientId}`,
    patientData,
  );

export const apiRequestAppointmentForPartner = (
  partnerId,
  providerId,
  patient,
  requestedSlot,
  offer,
  requestData,
  offeredAppointmentTypeId,
  insuranceData,
) =>
  request(POST, `${CONFIG.API_URL}/partner/${partnerId}/appointment`, {
    providerId,
    insuranceData,
    insuranceFeedbackId: insuranceData.insuranceFeedbackId || null,
    offeredAppointmentType: offeredAppointmentTypeId,
    offerType: _.get(offer, 'active') ? offer.id : undefined,
    patient: {
      dateOfBirth: patient.dateOfBirth || null,
      email: patient.email,
      firstName: patient.firstName,
      gender: patient.gender || null,
      lastName: patient.lastName,
      mobilePhone: patient.mobilePhone,
      settings: patient.settings || {},
    },
    requestBlocks: null,
    requestData,
    reservedAppointmentSlot:
      // The API expects manually recurring IB slots to have type 'Opencare Automatic' and NOT have an id, so we clear it here
      // The front-end expects an id for each slot so for these specific slots we generate a fake id in the API
      requestedSlot && requestedSlot.type !== 'Opencare Automatic'
        ? requestedSlot.id
        : null,
    reservedSlotDetails: {
      date: requestedSlot ? requestedSlot.date : null,
      provider: requestedSlot
        ? requestedSlot.provider.id || requestedSlot.provider
        : null,
      time: requestedSlot ? requestedSlot.time : null,
      type: _.get(requestedSlot, 'type', 'Opencare'),
      metadata: requestedSlot ? requestedSlot.metadata : null,
    },
  });

export const apiGetLeadPartners = location => {
  const { latitude, longitude } = location;
  const { country, locality } = location.address;
  const state = location.address.administrative_area_level_1_short;
  const specialty = SPECIALTY.dentist;

  const params = [
    `latitude=${latitude}`,
    `longitude=${longitude}`,
    `country=${country}`,
    `locality=${locality}`,
    `state=${state}`,
    `specialty=${specialty}`,
  ];

  return request(GET, `${CONFIG.API_URL}/partner/lead/?${params.join('&')}`);
};

export const apiSendLeadToPartner = (
  partnerId,
  patientInformation,
  location,
  leadInformation,
) =>
  request(POST, `${CONFIG.API_URL}/partner/${partnerId}/lead`, {
    firstName: patientInformation.firstName,
    lastName: patientInformation.lastName,
    email: patientInformation.email,
    phoneNumber: patientInformation.phoneNumber,
    address: location,
    ...leadInformation,
  });
