import request, { GET, POST, PUT } from '../utils/request';
import { SPECIALTY } from 'common/constants';

export const apiCreateMatch = (
  location,
  other,
  funnelConfiguration,
  matchResultsPageConfiguration,
) =>
  request(POST, `${CONFIG.API_URL}/match/v2`, {
    specialty: SPECIALTY.dentist,
    location,
    other,
    funnelConfiguration,
    matchResultsPageConfiguration,
  });

export const apiCreateMatchForPartner = (partnerId, location, other) =>
  request(POST, `${CONFIG.API_URL}/partner/${partnerId}/match`, {
    specialty: SPECIALTY.dentist,
    location,
    other,
  });

export const apiGetMatch = (matchId, matchToken, createNewMatch, offerCode) =>
  request(GET, `${CONFIG.API_URL}/match/v3/${matchId}`, {
    params: {
      token: matchToken || '',
      populate: true,
      createNewMatch: !!createNewMatch,
      offerCode: offerCode || undefined,
    },
  });

export const apiGetDirectMatch = (matchId, userId) =>
  request(GET, `${CONFIG.API_URL}/match/v3/${matchId}`, {
    params: {
      populate: true,
      matchUser: userId,
    },
  });

export const apiGetReferralPromotion = referralCode =>
  request(GET, `${CONFIG.API_URL}/referralPromotion/eligible/${referralCode}`);

export const apiGetOffer = offerCode =>
  request(GET, `${CONFIG.API_URL}/offerType/${offerCode}`);

export const apiSendMatchEmail = (matchId, matchToken, email, source) =>
  request(POST, `${CONFIG.API_URL}/match/${matchId}/email`, {
    params: {
      token: matchToken,
    },
    email,
    source,
  });

export const apiUpdateMatch = (
  matchId,
  matchToken,
  { experimentData, offerCode, userId },
) => {
  request(
    PUT,
    `${CONFIG.API_URL}/match/${matchId}`,
    {
      experimentData,
      offerCode,
    },
    {
      params: {
        token: matchToken,
        matchUser: userId,
      },
    },
  );
};

export const apiPostFeedback = (source, data) => {
  request(POST, `${CONFIG.API_URL}/feedback`, {
    source,
    data,
  });
};

export const apiEmailMatchClinic = (matchId, clinicId, source) =>
  request(POST, `${CONFIG.API_URL}/match/${matchId}/clinic/${clinicId}/email`, {
    source,
  });
