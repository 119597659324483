/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import NotAuthorizedPage from 'containers/NotAuthorizedPage/Loadable';
import ErrorPage from 'containers/ErrorPage/Loadable';
import PricingPage from 'containers/PricingPage/Loadable';
import FunnelPage from 'containers/FunnelPage/Loadable';
import ResultsPage from 'containers/ResultsPage/Loadable';
import PartnerPage from 'containers/PartnerPage/Loadable';
import ProfilePage from 'containers/ProfilePage/Loadable';
import ConciergePage from '../ConciergePage/Loadable';
import injectSaga from 'utils/injectSaga';
import {
  initPypestream,
  setupPypestream,
  configurePypestream,
} from 'utils/pypestream';
import {
  requestUserAndPatient,
  updatePypestreamReady,
} from '../../connect/actions';
import { GetExperimentVariants } from '../../connect/saga/handlers/experiment/get-experiment-variants';
import GlobalStyle from '../../global-styles';
import saga from '../../connect/saga/saga';
import { selectDataUser } from '../../connect/selectors';
import CONFIG from '../../../app.config';
import RouteListener from './RouteListener';
import { Helmet } from 'react-helmet';
import {
  ERROR_PATH,
  NOT_AUTHORIZED_PATH,
  PARTNER_PATH,
  RECOMMENDATION_PATH,
  RESULTS_PATH,
  PRICING_PATH,
  CONCIERGE_PATH,
} from './Routes';
import { EXPERIMENTS } from 'utils/constants/experiment-constants';
import { getParam } from 'pages/common/helpers/url-helpers';
import { ForcedExperimentVariantsStateHandler } from 'connect/state/handlers/experiments';

const App = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { search } = location;

  const user = useSelector(selectDataUser);

  useEffect(() => {
    dispatch(GetExperimentVariants.createAction());
    dispatch(requestUserAndPatient());
  }, []);

  initPypestream();

  useEffect(() => {
    if (user) {
      setupPypestream(user);

      // Wait for Pypestream to be ready
      const setupInterval = setInterval(() => {
        if (!window.Pypestream) {
          return;
        }

        configurePypestream();
        // Set Pypestream ready flag to show help button
        dispatch(updatePypestreamReady(true));

        clearInterval(setupInterval);
      }, 1000);
    }
  }, [user]);

  useEffect(() => {
    const experimentNames = Object.values(EXPERIMENTS).map(
      experiment => experiment.name,
    );
    const requestedExperimentVariants = getParam(search, ...experimentNames);

    const experimentVariants = requestedExperimentVariants.reduce(
      (acc, variant, index) => {
        if (!variant) {
          return acc;
        }

        const experimentName = experimentNames[index];
        return {
          ...acc,
          [experimentName]: variant,
        };
      },
      {},
    );

    // Update forced experiments even if no variants are given
    // This will clear out any previously forced experiments
    dispatch(ForcedExperimentVariantsStateHandler.update(experimentVariants));
  }, []);

  return (
    <>
      <Helmet>
        <title>Opencare - Find a Dentist Near You</title>
        <base href={CONFIG.SEARCH_APP_V2_URL} />
      </Helmet>
      <BrowserRouter>
        <RouteListener>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={RESULTS_PATH} />}
            />
            {CONFIG.FUNNEL && (
              <Route path={RECOMMENDATION_PATH}>
                {user.partner ? <Redirect to={PARTNER_PATH} /> : <FunnelPage />}
              </Route>
            )}
            <Route exact path={RESULTS_PATH}>
              {user.partner ? <Redirect to={PARTNER_PATH} /> : <ResultsPage />}
            </Route>
            <Route exact path={PRICING_PATH} render={() => <PricingPage />} />
            <Route exact path={PARTNER_PATH} render={() => <PartnerPage />} />
            <Route path={CONCIERGE_PATH}>
              {user.partner ? (
                <Redirect to={PARTNER_PATH} />
              ) : (
                <ConciergePage />
              )}
            </Route>
            <Route
              component={ProfilePage}
              path={`${RESULTS_PATH}/:clinicId(\\d+)`}
            />
            <Route component={NotAuthorizedPage} path={NOT_AUTHORIZED_PATH} />
            <Route component={ErrorPage} path={ERROR_PATH} />
            <Route component={NotFoundPage} />
          </Switch>
        </RouteListener>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

const withSaga = injectSaga({ key: 'rootSaga', saga });

export default compose(withSaga)(App);
