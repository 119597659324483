import Rollbar from 'rollbar';

const configure = () => {
  let environment = process.env.NODE_ENV;

  if (process.env.ENV_STAGING === 'true') {
    environment = 'staging';
  } else if (process.env.ENV_DEMO === 'true') {
    environment = 'demo';
  }

  window.Rollbar = new Rollbar({
    accessToken: '0714bc4786cd49a89091a3f2d860ef33',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment,
    },
  });
};

const debug = (message, params = {}) => {
  window.Rollbar.debug(message, params);
};

const info = (message, params = {}) => {
  window.Rollbar.info(message, params);
};

const warning = (message, params = {}) => {
  window.Rollbar.warning(message, params);
};

const error = (message, params = {}) => {
  window.Rollbar.error(message, params);
};

const critical = (message, params = {}) => {
  window.Rollbar.critical(message, params);
};

export default {
  configure,
  debug,
  info,
  warning,
  error,
  critical,
};
