import { call } from 'redux-saga/effects';
import { sendToLogger } from 'connect/saga/helpers';
import { SagaHandler } from '../SagaHandler';
import request, { POST } from 'utils/request';

const apiUpsertIterableUser = (dataFields, allowEmptyDataFields) =>
  request(POST, `${CONFIG.API_URL}/iterable/user/custom`, {
    dataFields: dataFields ?? {},
    allowEmptyDataFields,
  });

/*
  Takes user ID from cookie by default and creates a user in Iterable with that data
  Also allows adding additional custom dataFields (user properties) e.g. `hasStartedCheckout`
*/
class _UpsertIterableUser extends SagaHandler {
  constructor() {
    super({
      actionName: 'data/UPSERT_ITERABLE_USER',
    });
  }

  *saga({ payload }) {
    const { dataFields, allowEmptyDataFields } = payload ?? {};
    try {
      // Update user in Iterable - this will update the startedCheckoutAt field there
      yield call(apiUpsertIterableUser, dataFields, allowEmptyDataFields);
    } catch (error) {
      sendToLogger(error, 'UpsertIterableUser');
    }
  }
}

export const UpsertIterableUser = new _UpsertIterableUser();
