import { ReduxStateHandler } from 'connect/state/ReduxStateHandler';

const createSubHandler = (actionName, stateKey, legacyKey) =>
  new ReduxStateHandler(actionName, stateKey, {
    legacyKey,
  });

/**
 * Base class for saga handlers
 *
 * Note: Saga handlers must be registered manually in the `saga-registry` file.
 * @abstract
 */
export class SagaHandler {
  constructor({
    actionName,
    stateKey = null,
    legacyOptions = {},
    dependencies = [],
  }) {
    this._actionName = actionName;
    this._stateKey = stateKey;
    this._dependencies = dependencies;
    this._lastState = {};

    this.saga = this.saga.bind(this);
    this.selector = this.selector.bind(this);
    this.injectDependencies = this.injectDependencies.bind(this);

    if (stateKey) {
      const legacyErrorStateKey = legacyOptions.errorStateKey;
      this.error = createSubHandler(
        `${actionName}/ERROR`,
        `${stateKey}.error`,
        legacyErrorStateKey,
      );

      const legacyLoadingStateKey = legacyOptions.loadingStateKey;
      this.loading = createSubHandler(
        `${actionName}/LOADING`,
        `${stateKey}.loading`,
        legacyLoadingStateKey,
      );

      const legacyValueStateKey = legacyOptions.valueStateKey;
      this.value = createSubHandler(
        `${actionName}/VALUE`,
        `${stateKey}.value`,
        legacyValueStateKey,
      );

      this._stateHandler = new ReduxStateHandler(
        `${actionName}/STATE`,
        stateKey,
      );
    }
  }

  /**
   * Used to inject other saga handlers from the registry
   * This allows any handler to access other handlers
   * Without circular dependencies
   * @param {SagaHandlerRegistry} registry
   */
  injectDependencies(registry) {
    this._dependencies = this._dependencies.reduce(
      (acc, dependency) => ({
        ...acc,
        [dependency]: registry.getHandler(dependency),
      }),
      {},
    );
  }

  get actionName() {
    return this._actionName;
  }

  selector(state) {
    if (!this._stateKey) {
      throw new Error('This handler does not have a state.');
    }

    // Use the sub handlers to get the values
    // so that legacy options are respected
    const newState = {
      error: this.error.selector(state),
      loading: this.loading.selector(state),
      value: this.value.selector(state),
    };

    const hasChanged = Object.keys(newState).some(
      key => newState[key] !== this._lastState[key],
    );

    // However returning a new reference will trigger a re-render
    // So we keep returning the same reference if nothing has changed
    if (!hasChanged) {
      return this._lastState;
    }

    this._lastState = newState;
    return newState;
  }

  // eslint-disable-next-line no-unused-vars
  createAction(value) {
    return {
      type: this._actionName,
      payload: value,
    };
  }

  /**
   * Resets the value, loading, and error states to their initial values
   * @returns {function} A redux action creator
   * @example
   * ```javascript
   * const action = handler.createResetAction();
   * action(dispatch);
   * ```
   */
  // eslint-disable-next-line no-unused-vars
  reset() {
    if (!this._stateKey) {
      throw new Error('This handler does not have a state.');
    }

    return {
      type: `${this._actionName}/STATE`,
      payload: {
        error: null,
        loading: false,
        value: null,
      },
    };
  }

  // eslint-disable-next-line no-unused-vars
  saga(payload) {
    throw new Error('saga not implemented');
  }
}
