import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @keyframes FADE-IN {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  /* This is to override styles of antd text components where there is additional spacing added to anything with a font-weight of 400 */
  * {
    font-variant: none !important;
    font-feature-settings: normal !important;
  }

  /* Add outline in default browser color when element is focused with keyboard */
  *:not(.ant-select-selection-search-input):focus-visible, .ant-radio-button-wrapper:focus-within, .ant-select:focus-within {
    outline: auto Highlight !important; // Firefox
    outline: auto -webkit-focus-ring-color !important;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  #app {
    /* This animation is temporary for redirecting from search-v1 */
    animation: FADE-IN 1s;
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
    transition: opacity 1s;
    display: flex;
    flex-direction: column;

    > * {
      flex: 1;
    }
  }

  p,
  label {
    line-height: 1.5em;
  }

  ::-webkit-input-placeholder {
    opacity: 1;
  }
`;

export default GlobalStyle;
